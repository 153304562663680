import Form from './Form';
import Layout from './Layout';

export default function LoginPage() {
  return (
    <Layout>
      <Form />
    </Layout>
  );
}
