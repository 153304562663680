import { Divider, Layout, notification, Table, Tabs, Tag } from 'antd';
import { CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { PageHeader } from '@ant-design/pro-components';
import { useSearchParams } from 'react-router-dom';
import {
  CommentReportResponseModel,
  CommunityPostReportDetailResponseModel,
  CommunityReportListModel,
  ReportReason,
  ReportState
} from '@uniquegood/realworld-admin-interface/dist';
import DefaultLayout from '@src/components/DefaultLayout';
import Filter from '@src/components/UserCommunity/Filter';
import { projectApi } from '@src/apis/admin';
import ReportDetailModal from '@src/components/UserCommunity/ReportDetailModal';
import { YYYY_MM_DD } from '@src/constants/date';
import { ContentRequestType } from '@src/models/communityContentRequestType';
import { communityReportReasonLabel } from '@src/constants/communityReason';
import { communityReportReason } from '@src/models/communityReportReason';
import { communityReportState } from '@src/models/communityReportState';
import { communityReportStateLabel } from '@src/constants/communityState';

const { Content } = Layout;

const defaultPaginationSet = {
  pageNumber: 1,
  reportPerPage: 50
};

function CommunityReportList() {
  const [contentRequestType, setContentRequestType] = useState<ContentRequestType>('post');
  const [pageNumber, setPageNumber] = useState(defaultPaginationSet.pageNumber);
  const [reportPerPage, setReportPerPage] = useState(defaultPaginationSet.reportPerPage);
  const [totalReports, setTotalReports] = useState<number>();
  const [reportList, setReportList] = useState<CommunityReportListModel[]>([]);
  const [reportDetail, setReportDetail] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedReportForModal, setSelectedReportForModal] = useState<
    | {
        reportId: string;
      }
    | undefined
  >();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    getReportList(contentRequestType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, contentRequestType]);

  function handleChangeContentRequestType(contentRequestType: string) {
    (function resetSearchParams() {
      setSearchParams(new URLSearchParams());
    })();

    setContentRequestType(contentRequestType as ContentRequestType);
  }

  async function getReportList(contentRequestType: 'post' | 'comment') {
    const parsedParams = Object.fromEntries(searchParams);
    if (Object.keys(parsedParams).length === 0) return;

    try {
      if (contentRequestType === 'post') {
        const {
          searchStartDate,
          searchEndDate,
          searchReason,
          searchStatus,
          searchWriter,
          searchContent,
          searchDescription,
          page,
          take
        } = parsedParams;

        setIsLoading(true);
        const { data } = await projectApi.getReportComments(
          'CreatedAt',
          searchStartDate,
          searchEndDate,
          searchStatus as ReportState,
          searchContent,
          searchDescription,
          searchReason as ReportReason,
          searchWriter,
          'Post',
          page ? Number(page) : undefined, // page
          take ? Number(take) : undefined // take
        );

        const list = data.data.data;

        setTotalReports(Number(list?.length));
        if (list) {
          setReportList(
            list.map((item: CommunityReportListModel) => ({
              ...item,
              createdAtLabel: dayjs(item.createdAt).format(YYYY_MM_DD),
              reasonLabel: communityReportReasonLabel[item.reason as communityReportReason],
              isDeletedLabel: item.isDeleted ? 'O' : 'X',
              stateLabel: communityReportStateLabel[item.state as communityReportState],
              reportedUserName: item.name
            }))
          );
        }
        setIsLoading(false);
      } else {
        const parsedParams = Object.fromEntries(searchParams);
        const {
          searchStartDate,
          searchEndDate,
          searchStatus,
          searchContent,
          searchDescription,
          searchReason,
          searchWriter,
          page,
          take
        } = parsedParams;

        setIsLoading(true);
        const { data } = await projectApi.getReportComments(
          'CreatedAt',
          searchStartDate,
          searchEndDate,
          searchStatus as ReportState,
          searchContent,
          searchDescription,
          searchReason as ReportReason,
          searchWriter,
          'Comment',
          page ? Number(page) : undefined,
          take ? Number(take) : undefined
        );

        const list = data.data.data;

        setTotalReports(Number(list?.length));
        if (list) {
          setReportList(
            list.map((item: CommunityReportListModel) => ({
              ...item,
              createdAtLabel: dayjs(item.createdAt).format(YYYY_MM_DD),
              reasonLabel: communityReportReasonLabel[item.reason as communityReportReason],
              isDeletedLabel: item.isDeleted ? 'O' : 'X',
              stateLabel: communityReportStateLabel[item.state as communityReportState],
              reportedUserName: item.name
            }))
          );
        }
        setIsLoading(false);
      }
    } catch (err) {
      console.error(err);
    }
  }

  async function getReportDetail(record: CommunityPostReportDetailResponseModel) {
    setReportDetail({
      ...record,
      createdAtLabel: dayjs(record.createdAt).format(YYYY_MM_DD),
      reasonLabel: communityReportReasonLabel[record.reason as communityReportReason],
      isDeletedLabel: record.isDeleted ? 'O' : 'X',
      stateLabel: communityReportStateLabel[record.state as communityReportState]
    });
  }

  async function patchReportProcess(reportId: string, process: ReportState) {
    try {
      const data = await projectApi.handleRatingCommentReport(
        reportId,
        {
          handlingType: process
        },
        {
          validateStatus: (status: number) => {
            return status < 500 || status !== 409;
          }
        }
      );

      if (data.status === 409) {
        openFailNotification();
      }
    } catch (err) {
      console.log(err);
      openFailNotification();
    } finally {
      handleOnProcessed(process);
    }
  }

  function handleModalClose() {
    setSelectedReportForModal(undefined);
  }

  function handleReportDetailModal(record: CommunityReportListModel) {
    getReportDetail(record);
    setSelectedReportForModal({ reportId: record.reportId || '' });
  }

  function handleReportProcess(process: ReportState) {
    if (!selectedReportForModal) return;

    patchReportProcess(selectedReportForModal.reportId, process);
    setSelectedReportForModal(undefined);
  }

  const openSuccessNotification = (process: string) => {
    if (process === 'deleted') {
      notification.open({
        message: '삭제 처리 완료',
        description: '신고 내용이 삭제 처리되었습니다.',
        icon: <ExclamationCircleOutlined style={{ color: '#ff4d4f' }} />,
        placement: 'bottomRight'
      });
    }

    if (process === 'rejected') {
      notification.open({
        message: '반려 처리 완료',
        description: '신고 내용이 반려 처리되었습니다.',
        icon: <ExclamationCircleOutlined style={{ color: '#faad14' }} />,
        placement: 'bottomRight'
      });
    }
  };

  const openFailNotification = () => {
    notification.open({
      message: '신고 처리 실패',
      description: '이미 처리되었거나 삭제된 신고 내용입니다.',
      icon: <CloseCircleOutlined style={{ color: '#ff4d4f' }} />,
      placement: 'bottomRight'
    });
  };

  async function handleOnProcessed(process: string) {
    openSuccessNotification(process);
    getReportList(contentRequestType);
  }

  const columns = [
    {
      title: '신고 일자',
      dataIndex: 'createdAtLabel',
      key: 'createdAtLabel'
    },
    {
      title: '신고 분류',
      dataIndex: 'reasonLabel',
      key: 'reasonLabel'
    },
    {
      title: '글 분류',
      render: (value: CommentReportResponseModel) => {
        if (contentRequestType === 'comment') return '댓글 및 답글';

        return value.rating !== null ? '후기글' : '도움글';
      }
    },
    {
      title: '프로젝트 이름',
      dataIndex: 'projectName',
      key: 'projectName'
    },
    {
      title: '작성자',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '신고 사유',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: '신고내용',
      dataIndex: 'content',
      key: 'content'
    },
    {
      title: '상태',
      dataIndex: 'stateLabel',
      key: 'stateLabel',
      render: (stateLabel: string) => {
        switch (stateLabel) {
          case '삭제됨':
            return <Tag color="red">{stateLabel}</Tag>;
          case '반려됨':
            return <Tag color="gray">{stateLabel}</Tag>;
          case '스포일러 처리':
            return <Tag color="#c869ff">{stateLabel}</Tag>;
          default:
            return <Tag>{stateLabel}</Tag>;
        }
      }
    },
    {
      title: '삭제여부',
      dataIndex: 'isDeletedLabel',
      key: 'isDeletedLabel'
    }
  ];

  const tabItems: { label: string; key: ContentRequestType; children: JSX.Element }[] = [
    {
      label: '게시글',
      key: 'post',
      children: (
        <>
          <Filter />
          <Table<CommunityReportListModel>
            dataSource={reportList}
            columns={columns}
            rowKey={(record) => (typeof record.reportId === 'string' ? record.reportId : '')}
            loading={isLoading}
            pagination={{
              pageSizeOptions: [50, 100],
              defaultPageSize: 50,
              total: totalReports,
              pageSize: reportPerPage,
              onChange: (page, pageSize) => {
                setPageNumber(page);
                setReportPerPage(pageSize);
              }
            }}
            scroll={{ y: 600 }}
            onRow={(record) => {
              return {
                onClick: () => {
                  if (record) {
                    handleReportDetailModal(record);
                  }
                }
              };
            }}
          />
        </>
      )
    },
    {
      label: '댓글﹒답글',
      key: 'comment',
      children: (
        <>
          <Filter />
          <Table
            dataSource={reportList}
            columns={columns}
            rowKey={(record) => (record.reportId && typeof record.reportId === 'string' ? record.reportId : '')}
            loading={isLoading}
            pagination={{
              pageSizeOptions: [50, 100],
              defaultPageSize: 50,
              total: totalReports,
              pageSize: reportPerPage,
              onChange: (page, pageSize) => {
                setPageNumber(page);
                setReportPerPage(pageSize);
              }
            }}
            scroll={{ y: 600 }}
            onRow={(record) => {
              return {
                onClick: () => {
                  if (record) {
                    handleReportDetailModal(record);
                  }
                }
              };
            }}
          />
        </>
      )
    }
  ];

  return (
    <DefaultLayout>
      <Layout>
        <PageHeader
          title="후기글/도움글 관리"
          subTitle="신고된 후기글/도움글과 댓글을 관리합니다"
          style={{ margin: '16px 20px 0px 20px' }}
        />
        <Divider />
        <Content style={{ padding: '0px 24px', margin: '0px 20px' }}>
          <Tabs type="card" items={tabItems} onTabClick={handleChangeContentRequestType} />
        </Content>
      </Layout>

      {/* 신고 상세 모달 */}
      {reportDetail && (
        <ReportDetailModal
          reportDetail={reportDetail}
          isModalOpen={Boolean(selectedReportForModal)}
          handleModalClose={handleModalClose}
          handleReportProcess={handleReportProcess}
        />
      )}
    </DefaultLayout>
  );
}

export default CommunityReportList;
