import { PageHeader } from '@ant-design/pro-components';
import { Col, Divider, Row, Table, Tag } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useParams } from 'react-router-dom';
import { useAsync } from 'react-use';
import { css } from '@emotion/react';
import Title from 'antd/es/typography/Title';
import { ChannelType } from '@uniquegood/realworld-admin-interface';
import DefaultLayout from '@src/components/DefaultLayout';
import { applicationApi } from '@src/apis/admin';
import { NarrowChannelType, channelTypeToLabel, creatorTypeToLabel } from '@src/constants/channel';
import ProfileList from './ProfileList';

interface Creator {
  item1: string;
  item2: {
    email: string;
    id: string;
    name: string;
    phone: string;
    profileImage: string;
    role: string | null;
  };
}

export default function ChannelDetail() {
  const { id } = useParams();

  const channelDetailData = useAsync(async () => {
    const { data } = await applicationApi.getApplication(String(id));

    return data.data;
  }, []);

  const columns = [
    {
      key: 'role',
      dataIndex: 'role',
      title: '권한',
      render: (value: string) => creatorTypeToLabel[value]
    },
    {
      key: 'name',
      dataIndex: 'name',
      title: '이름'
    },
    {
      key: 'email',
      dataIndex: 'email',
      title: '이메일'
    },
    {
      key: 'phone',
      dataIndex: 'phone',
      title: '연락처'
    }
  ];

  return (
    <DefaultLayout>
      <PageHeader title="채널 상세" subTitle="채널 상세 정보를 조회합니다." style={{ margin: '16px 20px 0px 20px' }} />
      <Divider />
      <Content style={{ padding: '0 24px', margin: '0px 20px', marginBottom: '44px' }}>
        <Row gutter={[16, 16]}>
          <Col span={24} css={ColumnItemStyle}>
            <div>
              <Title level={2} style={{ display: 'flex', alignItems: 'center' }}>
                {channelDetailData.value?.applicationName}{' '}
                <Tag
                  color={channelDetailData.value?.channelType === ChannelType.Normal ? '#777' : '#c869ff'}
                  style={{ marginLeft: '16px' }}
                >
                  {channelTypeToLabel[channelDetailData.value?.channelType as NarrowChannelType]}
                </Tag>
              </Title>{' '}
            </div>
            <div>채널 ID: {channelDetailData.value?.applicationId}</div>
          </Col>
          <Col span={24} css={ColumnItemStyle}>
            <Title level={2}>소유자</Title>
            <ProfileList data={channelDetailData.value?.owners || []} />
          </Col>
          <Col span={24} css={ColumnItemStyle}>
            <Title level={2}>편집자</Title>
            <ProfileList data={channelDetailData.value?.authors || []} />
          </Col>
          <Col span={24} css={ColumnItemStyle}>
            <Title level={2}>테스터</Title>
            <ProfileList data={channelDetailData.value?.testers || []} />
          </Col>
          <Col span={24} css={ColumnItemStyle}>
            <Title level={2}>소유자/편집자/테스터 상세 정보</Title>
            <Table
              dataSource={channelDetailData.value?.creators?.map((creator) => {
                const creatorData = creator as Creator;

                return {
                  role: creatorData.item1,
                  name: creatorData.item2.name,
                  email: creatorData.item2.email,
                  phone: creatorData.item2.phone
                };
              })}
              columns={columns}
              pagination={false}
              rowKey="phone"
            />
          </Col>
        </Row>
      </Content>
    </DefaultLayout>
  );
}

const ColumnItemStyle = css`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 16px !important;
  border-radius: 16px;
  background-color: #fff;
`;
