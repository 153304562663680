import { PageHeader } from '@ant-design/pro-components';
import { Divider, Table } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useParams } from 'react-router-dom';
import { useAsync } from 'react-use';
import { ScenariosResponseModel } from '@uniquegood/realworld-admin-interface';
import { ColumnsType } from 'antd/es/table';
import DefaultLayout from '@src/components/DefaultLayout';
import { scenarioApi } from '@src/apis/admin';

export default function Mission() {
  const { projectId, scenarioId } = useParams();

  const missionList = useAsync(async () => {
    const { data } = await scenarioApi.getScenarioMissions(projectId || '', Number(scenarioId));

    return data.data;
  }, []);

  const columns: ColumnsType<ScenariosResponseModel> = [
    {
      key: 'id',
      dataIndex: 'id',
      title: 'ID'
    },
    {
      key: 'name',
      dataIndex: 'name',
      title: '이름'
    },
    {
      key: 'isLockedDefault',
      dataIndex: 'isLockedDefault',
      title: '기본 잠금 여부',
      render: (value) => (value ? '잠금' : '미잠금')
    },
    {
      key: 'isVisibleDefault',
      dataIndex: 'isVisibleDefault',
      title: '기본 노출 여부',
      render: (value) => (value ? '노출' : '미노출')
    },
    {
      key: 'completeCount',
      dataIndex: 'completeCount',
      title: '완료 수'
    },
    {
      key: 'questCount',
      dataIndex: 'questCount',
      title: '퀘스트 수'
    }
  ];

  return (
    <DefaultLayout>
      <PageHeader
        title="프로젝트 미션 목록"
        subTitle="프로젝트 미션 목록을 조회합니다."
        style={{ margin: '16px 20px 0px 20px' }}
      />
      <Divider />
      <Content style={{ padding: '0 24px', margin: '0px 20px', marginBottom: '44px' }}>
        <Table dataSource={missionList.value} columns={columns} pagination={false} />
      </Content>
    </DefaultLayout>
  );
}
