import { PageHeader } from '@ant-design/pro-components';
import { Col, Divider, Row, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import {
  Chart as Chartjs,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  LineController,
  LineElement,
  PointElement
} from 'chart.js';
import { useAsync } from 'react-use';
import { css } from '@emotion/react';
import { PointFilterType } from '@uniquegood/realworld-admin-interface/dist';
import DefaultLayout from '@src/components/DefaultLayout';
import { PeriodChart } from '@src/components/PeriodChart';
import { pointApi } from '@src/apis/admin';

const { Title } = Typography;

Chartjs.register(
  CategoryScale,
  LinearScale,
  ArcElement,
  Tooltip,
  Legend,
  BarController,
  BarElement,
  LineController,
  LineElement,
  PointElement
);

export default function PointDashboard() {
  const churroInfoDataApi = useAsync(async () => {
    const { data: all } = await pointApi.getPointIndicators(PointFilterType.All);
    const { data: paid } = await pointApi.getPointIndicators(PointFilterType.Paid);
    const { data: free } = await pointApi.getPointIndicators(PointFilterType.Free);
    const { data: totalPurchase } = await pointApi.getTotalPointPayment();

    return {
      all,
      paid,
      free,
      totalPurchase
    };
  }, []);

  return (
    <DefaultLayout>
      <PageHeader
        title="츄로 대시보드"
        subTitle="츄로 데이터를 확인할 수 있습니다."
        style={{ margin: '16px 20px 0px 20px' }}
      />
      <Divider />
      <Content
        style={{
          position: 'relative',
          padding: '0px 24px',
          margin: '0px 20px',
          paddingBottom: '20px'
        }}
      >
        <Row gutter={[24, 24]} style={{ marginBottom: '24px' }}>
          <Col flex={1}>
            <div css={ColumnItemStyle}>
              <Typography.Title level={5} type="secondary">
                총 츄로 발행량
              </Typography.Title>
              <Typography.Title level={3}>
                {churroInfoDataApi.value?.all.data?.circulationQuantity?.toLocaleString()}
              </Typography.Title>
            </div>
          </Col>
          <Col flex={1}>
            <div css={ColumnItemStyle}>
              <Typography.Title level={5} type="secondary">
                총 마이 츄로 발행량
              </Typography.Title>
              <Typography.Title level={3}>
                {churroInfoDataApi.value?.paid.data?.circulationQuantity?.toLocaleString()}
              </Typography.Title>
            </div>
          </Col>
          <Col flex={1}>
            <div css={ColumnItemStyle}>
              <Typography.Title level={5} type="secondary">
                총 보너스 츄로 발행량
              </Typography.Title>
              <Typography.Title level={3}>
                {churroInfoDataApi.value?.free.data?.circulationQuantity?.toLocaleString()}
              </Typography.Title>
            </div>
          </Col>
          <Col flex={1}>
            <div css={ColumnItemStyle}>
              <Typography.Title level={5} type="secondary">
                총 츄로 사용량
              </Typography.Title>
              <Typography.Title level={3}>
                {churroInfoDataApi.value?.all.data?.usageQuantity?.toLocaleString()}
              </Typography.Title>
            </div>
          </Col>
          <Col flex={1}>
            <div css={ColumnItemStyle}>
              <Typography.Title level={5} type="secondary">
                총 츄로 결제액
              </Typography.Title>
              <Typography.Title level={3}>
                {churroInfoDataApi.value?.totalPurchase.data?.totalPayment?.toLocaleString()}
              </Typography.Title>
            </div>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <div css={ColumnItemStyle}>
              <Title level={5} style={{ marginBottom: '16px' }}>
                츄로 결제 추이 차트
              </Title>
              <PeriodChart />
            </div>
          </Col>
        </Row>
      </Content>
    </DefaultLayout>
  );
}

const ColumnItemStyle = css`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 16px;
  border-radius: 16px;
  background-color: #fff;
`;
