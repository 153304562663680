import { PageHeader } from '@ant-design/pro-components';
import { PromotionListResponseModel, PromotionType } from '@uniquegood/realworld-admin-interface/dist';
import { Badge, Button, Divider, Modal, Space, Table, Tag } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import React from 'react';
import { useAsyncFn } from 'react-use';
import { uuidv4 } from '@src/utils/uuid';
import useModalState from '@src/hooks/useModalState';
import { PromotionTypeLabel } from '@src/constants/point';
import DefaultLayout from '@src/components/DefaultLayout';
import { promotionApi } from '@src/apis/admin';

import { PromotionCreateModal } from './PromotionCreateModal';
import { PromotionDeleteCheckModal } from './PromotionDeleteCheckModal';
import { PromotionDetailModal } from './PromotionDetailModal';
import { PromotionModifyModal } from './PromotionModifyModal';

export default function PointPromotion() {
  const { openModal: openDetailModal, closeModal: closeDetailModal, modal: detailModal } = useModalState();
  const { openModal: openCreateModal, closeModal: closeCreateModal, modal: createModal } = useModalState();
  const { openModal: openModifyModal, closeModal: closeModifyModal, modal: modifyModal } = useModalState();
  const {
    openModal: openDeleteCheckModal,
    closeModal: closeDeleteCheckModal,
    modal: deleteCheckModal
  } = useModalState();

  const [currentPage, setCurrentPage] = React.useState(1);
  const [selectedPromotionId, setSelectedPromotionId] = React.useState('');

  const [promotionApiState, fetchPromotionApiState] = useAsyncFn(async () => {
    const { data } = await promotionApi.getPromotionList(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      currentPage,
      20
    );

    return {
      ...data.data,
      data: data.data?.data?.map((item) => ({
        ...item,
        key: uuidv4(),
        createdAt: dayjs(item.createdAt).format('YYYY-MM-DD HH:mm:ss'),
        updatedAt: item.updatedAt ? dayjs(item.updatedAt).format('YYYY-MM-DD HH:mm:ss') : null,
        period:
          item.startedAt !== null && item.endedAt !== null
            ? `${dayjs(item.startedAt).format('YYYY-MM-DD')} ~ ${dayjs(item.endedAt).format('YYYY-MM-DD')}`
            : '상시'
      }))
    };
  }, []);

  const columns: ColumnsType<PromotionListResponseModel> = [
    {
      title: '프로모션 명',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: '프로모션 타입',
      dataIndex: 'promotionType',
      key: 'promotionType',
      render: (value: PromotionType) => {
        switch (value) {
          case PromotionType.Basic:
            return <Tag>{PromotionTypeLabel[value]}</Tag>;
          case PromotionType.Daily:
            return <Tag color="blue">{PromotionTypeLabel[value]}</Tag>;
          case PromotionType.Multi:
            return <Tag color="purple">{PromotionTypeLabel[value]}</Tag>;
          default:
            return null;
        }
      }
    },
    {
      title: '내용',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: '기간',
      dataIndex: 'period',
      key: 'period'
    },
    {
      title: '활성화 여부',
      dataIndex: 'isActivate',
      key: 'isActivate',
      render: (value) => {
        return (
          <div>{value ? <Badge dot color="green" text="활성화" /> : <Badge dot color="red" text="비활성화" />}</div>
        );
      }
    },
    {
      title: '관리자',
      dataIndex: 'manager',
      key: 'manager'
    },
    {
      title: '생성일',
      dataIndex: 'createdAt',
      key: 'createdAt'
    },
    {
      title: '마지막 수정일',
      dataIndex: 'updatedAt',
      key: 'updatedAt'
    },
    {
      title: '마지막 수정 관리자',
      dataIndex: 'lastEditor',
      key: 'lastEditor'
    },
    {
      title: '동작',
      onCell: () => {
        return {
          onClick: (e) => e.stopPropagation()
        };
      },
      render: (_, record) => {
        return (
          <>
            <Button
              onClick={async () => {
                if (typeof record.promotionId !== 'string') return;

                setSelectedPromotionId(record.promotionId);
                openModifyModal({
                  title: '프로모션 수정',
                  transitionName: ''
                });
              }}
              style={{ marginRight: '8px', border: '1px solid #f7b924', color: '#f7b924' }}
            >
              수정
            </Button>
            <Button
              danger
              onClick={() => {
                if (typeof record.promotionId !== 'string') return;

                setSelectedPromotionId(record.promotionId);
                openDeleteCheckModal({
                  title: '정말 삭제하시겠습니까?',
                  transitionName: ''
                });
              }}
            >
              삭제
            </Button>
          </>
        );
      }
    }
  ];

  React.useEffect(() => {
    fetchPromotionApiState();
  }, [fetchPromotionApiState]);

  return (
    <DefaultLayout>
      <PageHeader title="츄로 프로모션" subTitle="츄로 프로모션을 관리합니다." style={{ margin: '16px 20px 0 20px' }} />
      <Divider />
      <Content style={{ padding: '0 24px', margin: '0px 20px' }}>
        <Space style={{ width: '100%', marginBottom: '16px' }}>
          <Button
            onClick={() => {
              openCreateModal({
                title: '프로모션 추가',
                transitionName: ''
              });
            }}
          >
            프로모션 추가
          </Button>
        </Space>
        <Table
          dataSource={promotionApiState.value?.data}
          columns={columns}
          scroll={{ x: 'max-content' }}
          loading={promotionApiState.loading}
          pagination={{
            total: promotionApiState.value?.count
          }}
          onRow={(record) => {
            return {
              onClick: () => {
                setSelectedPromotionId(record.promotionId || '');
                openDetailModal({
                  title: '프로모션 상세',
                  transitionName: ''
                });
              }
            };
          }}
        />
      </Content>
      <Modal
        {...detailModal}
        width="1000px"
        footer={[
          <Button type="primary" onClick={closeDetailModal}>
            닫기
          </Button>
        ]}
      >
        <PromotionDetailModal promotionId={selectedPromotionId} />
      </Modal>
      <PromotionCreateModal
        modal={createModal}
        closeCreateModal={closeCreateModal}
        refetchFn={fetchPromotionApiState}
      />
      <PromotionModifyModal
        modal={modifyModal}
        promotionId={selectedPromotionId}
        refetchFn={fetchPromotionApiState}
        closeModifyModal={closeModifyModal}
      />
      <PromotionDeleteCheckModal
        modal={deleteCheckModal}
        closeModal={closeDeleteCheckModal}
        promotionId={selectedPromotionId}
        refetchFn={fetchPromotionApiState}
      />
    </DefaultLayout>
  );
}
