import { PageHeader } from '@ant-design/pro-components';
import { Button, Divider, Image, Switch, Table, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { CommunityBannerResponseModel, CommunityBannerType } from '@uniquegood/realworld-admin-interface';
import { css } from '@emotion/react';
import { SortableContext, arrayMove, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { MenuOutlined } from '@ant-design/icons';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import ConfirmDeleteModal from '@src/components/Modal/ConfirmDeleteModal';
import useModalState from '@src/hooks/useModalState';
import { communityBannerApi } from '@src/apis/admin';
import DefaultLayout from '@src/components/DefaultLayout';
import { communityBannerTypeToLabel } from '@src/constants/banner';
import ModifyBannerModal from './Modal/ModifyBannerModal';
import CreateBannerModal from './Modal/CreateBannerModal';

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

function Row({ children, ...props }: RowProps) {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({
    id: props['data-row-key']
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 1000 } : {})
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === 'sort') {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined ref={setActivatorNodeRef} style={{ touchAction: 'none', cursor: 'move' }} {...listeners} />
            )
          });
        }
        return child;
      })}
    </tr>
  );
}

export default function Banner() {
  const { openModal: openCreateModal, closeModal: closeCreateModal, modal: createModal } = useModalState();
  const { openModal: openModifyModal, closeModal: closeModifyModal, modal: modifyModal } = useModalState();
  const { openModal: openConfirmModal, closeModal: closeConfirmModal, modal: confirmModal } = useModalState();

  const [currentBanner, setCurrentBanner] = React.useState<CommunityBannerResponseModel>();
  const [banners, setBanners] = React.useState<CommunityBannerResponseModel[]>([]);

  const fetchBanners = React.useCallback(async () => {
    try {
      const { data } = await communityBannerApi.getBanners();

      setBanners(data.data);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const columns: ColumnsType<CommunityBannerResponseModel> = [
    {
      key: 'sort',
      width: 50,
      align: 'center'
    },
    {
      key: 'id',
      dataIndex: 'id',
      title: '배너 ID',
      width: 200,
      ellipsis: true
    },
    {
      key: 'title',
      dataIndex: 'title',
      title: '제목'
    },
    {
      key: 'subTitle',
      dataIndex: 'subTitle',
      title: '부제목'
    },
    {
      key: 'bannerType',
      dataIndex: 'bannerType',
      title: '배너 타입',
      render: (value: CommunityBannerType) => communityBannerTypeToLabel[value]
    },
    {
      key: 'imageUrl',
      dataIndex: 'imageUrl',
      title: '이미지',
      render: (value) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <span onClick={(e) => e.stopPropagation()}>
          <Image src={value} width={100} height={100} />
        </span>
      )
    },
    {
      key: 'isActive',
      dataIndex: 'isActive',
      title: '활성화 여부',
      render: (value, record) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div onClick={(e) => e.stopPropagation()}>
          <Switch
            checked={value}
            onChange={async () => {
              try {
                const { data } = await communityBannerApi.activationBanner(record.id || '');

                if (data.success) {
                  message.success('배너 활성화 여부를 변경했습니다.');
                  await fetchBanners();
                }
              } catch (e) {
                console.error(e);
                message.error('배너 활성화 여부 변경에 실패했습니다.');
              }
            }}
          />
        </div>
      )
    },
    {
      title: '동작',
      width: 100,
      render: (record) => (
        <Button
          danger
          size="small"
          onClick={(e) => {
            e.stopPropagation();

            openConfirmModal({
              onOk: async () => {
                try {
                  const { data } = await communityBannerApi.deleteBanner(record.id);

                  if (data.success) {
                    message.success('배너를 삭제했습니다.');
                    closeConfirmModal();
                    await fetchBanners();
                  }
                } catch (e) {
                  console.error(e);
                  message.error('배너 삭제에 실패했습니다.');
                }
              }
            });
          }}
        >
          삭제
        </Button>
      )
    }
  ];

  const onDragEnd = async ({ active, over }: DragEndEvent) => {
    const prevData = banners;

    try {
      if (active.id !== over?.id) {
        const activeIndex = banners.findIndex((i) => i.id === active.id);
        const overIndex = banners.findIndex((i) => i.id === over?.id);

        const nextData = arrayMove(banners, activeIndex, overIndex);

        setBanners(nextData);

        const { data } = await communityBannerApi.updateBannerOrder({
          currentBannerId: active.id as string,
          targetBannerId: over?.id as string
        });

        if (data.success) {
          message.success('배너 순서를 변경했습니다.');
        }
      }
    } catch (e) {
      console.error(e);
      message.error('배너 순서 변경에 실패했습니다.');
      setBanners(prevData);
    }
  };

  React.useEffect(() => {
    fetchBanners();
  }, [fetchBanners]);

  return (
    <>
      <DefaultLayout>
        <PageHeader
          title="커뮤니티 배너 관리"
          subTitle="커뮤니티 배너를 관리합니다."
          style={{ margin: '16px 20px 0px 20px' }}
        />
        <Divider />
        <Content style={{ padding: '0px 24px', margin: '0px 20px', marginBottom: '48px' }}>
          <div css={headerStyle}>
            <Button type="primary" onClick={() => openCreateModal({})}>
              배너 생성하기
            </Button>
          </div>
          <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
            <SortableContext items={banners.map((banner) => banner.id || '')} strategy={verticalListSortingStrategy}>
              <Table
                components={{
                  body: {
                    row: Row
                  }
                }}
                dataSource={banners}
                columns={columns}
                onRow={(record) => ({
                  onClick: () => {
                    setCurrentBanner(record);
                    openModifyModal({});
                  }
                })}
                pagination={false}
                rowKey="id"
              />
            </SortableContext>
          </DndContext>
        </Content>
      </DefaultLayout>
      <CreateBannerModal modalData={createModal} closeModal={closeCreateModal} refetch={fetchBanners} />
      <ModifyBannerModal
        modalData={modifyModal}
        closeModal={closeModifyModal}
        refetch={fetchBanners}
        initialData={currentBanner}
      />
      <ConfirmDeleteModal modalData={confirmModal} />
    </>
  );
}

const headerStyle = css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  margin-bottom: 16px;
`;
