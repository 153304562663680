import { useAsync, useAsyncFn } from 'react-use';
import { Button, Divider, Form, Input, notification } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import { Content } from 'antd/es/layout/layout';
import { SplashSettingRequestModel } from '@uniquegood/realworld-admin-interface';
import DefaultLayout from '@src/components/DefaultLayout';
import { noticeApi } from '@src/apis/admin';

function SplashScreenPage() {
  const splashState = useAsync(async () => {
    const { data } = await noticeApi.getSplashScreenSetting();

    return data.imageUrl;
  }, []);

  const [, onSplashScreenSave] = useAsyncFn(async (value: SplashSettingRequestModel) => {
    try {
      const { imageUrl } = value;
      const sendUrl = typeof imageUrl === 'string' && imageUrl === '' ? null : imageUrl;

      await noticeApi.setSplashScreenSetting({ imageUrl: sendUrl });

      notification.success({
        message: '저장 완료!',
        description: '스플래시 화면 변경사항이 성공적으로 저장되었습니다.',
        placement: 'bottomRight'
      });
    } catch (error) {
      notification.error({
        message: '저장 실패',
        description: '스플래시 화면 변경사항 저장에 실패했습니다.',
        placement: 'bottomRight'
      });
    }
  }, []);

  return (
    <DefaultLayout>
      <PageHeader
        title="스플래시 설정"
        subTitle="앱 구동시 표시되는 스플래시 화면을 설정합니다."
        style={{ margin: '16px 20px 0px 20px' }}
      />
      <Divider />
      <Content style={{ padding: '0px 24px', margin: '0px 20px' }}>
        {!splashState.loading && !splashState.error && (
          <Form<SplashSettingRequestModel>
            layout="vertical"
            size="large"
            onFinish={onSplashScreenSave}
            style={{
              padding: '18px 24px 4px 24px',
              backgroundColor: 'white',
              borderRadius: '8px',
              boxShadow: 'initial'
            }}
          >
            <Form.Item
              label="스플래시 이미지"
              name="imageUrl"
              extra="앱 초기 진입 시에 표시될 이미지입니다."
              initialValue={splashState.value}
            >
              <Input placeholder="이미지 링크 URL" />
            </Form.Item>
            <Form.Item style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button type="primary" htmlType="submit">
                저장
              </Button>
            </Form.Item>
          </Form>
        )}
      </Content>
    </DefaultLayout>
  );
}

export default SplashScreenPage;
