import { NoticePopup } from '@uniquegood/realworld-admin-interface/dist';
import { Checkbox, Form, Input, Modal, ModalProps, message } from 'antd';
import React from 'react';
import { noticeApi } from '@src/apis/admin';
import { Popup } from '.';

interface PopupModifyModalProps {
  modal: ModalProps;
  closeModal: () => unknown;
  currentValues: Popup[];
  selectedPopup: Popup;
  refetchFn: () => unknown;
}

export function PopupModifyModal({
  modal,
  closeModal,
  currentValues,
  selectedPopup,
  refetchFn
}: PopupModifyModalProps) {
  const [form] = Form.useForm();

  const handleOkClick = () => {
    form.submit();
  };

  const handleFinish = async (values: NoticePopup) => {
    try {
      const { link, image, message: title, isVisible } = values;

      await noticeApi.editNoticePopupsSetting({
        popups: currentValues.map((popup) => {
          if (popup.key === selectedPopup.key) {
            return {
              ...popup,
              link,
              image,
              message: title,
              isVisible
            };
          }
          return popup;
        })
      });

      await refetchFn();
      form.setFieldsValue({
        link: '',
        image: '',
        message: '',
        isVisible: null
      });
      message.success('수정 성공했습니다.');
      closeModal();
    } catch (e) {
      console.error(e);
      message.error('오류가 발생해 수정에 실패했습니다.');
    }
  };

  React.useEffect(() => {
    const { id, link, image, message, isVisible } = selectedPopup;

    form.setFieldsValue({
      id,
      link,
      image,
      message,
      isVisible
    });
  }, [form, selectedPopup]);

  return (
    <Modal {...modal} okText="확인" cancelText="취소" onOk={handleOkClick}>
      <Form form={form} onFinish={handleFinish}>
        <Form.Item name="id" hidden />
        <Form.Item name="message" rules={[{ required: true, message: '팝업 타이틀을 입력해주세요.' }]}>
          <Input placeholder="팝업 타이틀" />
        </Form.Item>
        <Form.Item name="link" rules={[{ required: true, message: 'URL을 입력해주세요.' }]}>
          <Input placeholder="클릭 시 이동할 URL" />
        </Form.Item>
        <Form.Item name="image" rules={[{ required: true, message: '팝업 이미지를 입력해주세요.' }]}>
          <Input placeholder="팝업 이미지" />
        </Form.Item>
        <Form.Item name="isVisible" valuePropName="checked">
          <Checkbox>팝업 표시 여부</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
}
