import { useMutation } from '@tanstack/react-query';
import { postLogin, postLogout, postRefreshToken } from '.';

export const usePostLoginMutation = () => {
  return useMutation({
    mutationKey: ['postLogin'],
    mutationFn: async ({ id, password }: { id: string; password: string }) => {
      const { data } = await postLogin({ id, password });

      return data;
    }
  });
};

export const usePostLogoutMutation = () => {
  return useMutation({
    mutationKey: ['postLogout'],
    mutationFn: async () => {
      const { data } = await postLogout();

      return data;
    }
  });
};

export const usePostRefreshMutation = () => {
  return useMutation({
    mutationKey: ['postRefresh'],
    mutationFn: async ({ accessToken, refreshToken }: { accessToken: string; refreshToken: string }) => {
      const { data } = await postRefreshToken({
        accessToken,
        refreshToken
      });

      return data;
    }
  });
};
