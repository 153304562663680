import { PageHeader } from '@ant-design/pro-components';
import { Button, Col, Divider, Row, Table, Tooltip, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useAsyncFn } from 'react-use';
import { ColumnsType } from 'antd/es/table';
import { FileInfoResponseModel } from '@uniquegood/realworld-admin-interface';
import React from 'react';
import { FileImageTwoTone, FolderOpenTwoTone, SoundTwoTone, UpOutlined, VideoCameraTwoTone } from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';
import DefaultLayout from '@src/components/DefaultLayout';
import useModalState from '@src/hooks/useModalState';
import { fileApi } from '@src/apis/admin';
import CreateFolderModal from './CreateFolderModal';
import FileUploadModal from './FileUploadModal';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import CreateOrModifyMetadataModal from './CreateOrModifyMetadataModal';

export default function File() {
  const [searchParams, setSearchParams] = useSearchParams();
  const directory = searchParams.get('directory') || '';

  const [currentFileId, setCurrentFileId] = React.useState('');
  const [currentMetadata, setCurrentMetadata] = React.useState('');

  const { openModal: openCreateModal, closeModal: closeCreateModal, modal: createModal } = useModalState();
  const { openModal: openUploadModal, closeModal: closeUploadModal, modal: uploadModal } = useModalState();
  const { openModal: openMetadataModal, closeModal: closeMetadataModal, modal: metadataModal } = useModalState();
  const { openModal: openConfirmModal, closeModal: closeConfirmModal, modal: confirmModal } = useModalState();

  const actionLoadingRef = React.useRef(false);

  const [fileList, fetchFileList] = useAsyncFn(async () => {
    try {
      const { data } = await fileApi.listFiles(undefined, undefined, undefined);

      const modifiedDirectory = directory.split('/');

      const { data: result } = await fileApi.listFiles(
        modifiedDirectory[modifiedDirectory.length - 1],
        data.data.count,
        1
      );

      if (directory) {
        return {
          ...result,
          data: {
            ...result.data,
            data: [{ name: '상위로 이동', mime: 'prev' }, ...(result.data.data || [])]
          }
        };
      }

      return result;
    } catch (e) {
      console.error(e);
      return null;
    } finally {
      actionLoadingRef.current = false;
    }
  }, [directory]);

  const columns: ColumnsType<FileInfoResponseModel> = [
    {
      title: '',
      dataIndex: 'mime',
      key: 'mime',
      width: 100,
      align: 'center',
      render: (value) => {
        if (!value) return <FolderOpenTwoTone style={{ fontSize: '18px' }} />;
        if (value.includes('image')) return <FileImageTwoTone style={{ fontSize: '18px' }} />;
        if (value.includes('video')) return <VideoCameraTwoTone style={{ fontSize: '18px' }} />;
        if (value.includes('audio')) return <SoundTwoTone style={{ fontSize: '18px' }} />;
        if (value === 'prev') return <UpOutlined style={{ fontSize: '14px' }} />;
        return null;
      }
    },
    {
      title: '이름',
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: '메타데이터',
      key: 'metaData',
      dataIndex: 'metaData'
    },
    {
      title: 'URL',
      key: 'url',
      dataIndex: 'url',
      width: 300,
      ellipsis: true,
      render: (value, record) => {
        if (record.isDirectory) return null;
        return (
          <Tooltip placement="topLeft" title={value}>
            <a href={value} target="blank" onClick={(e) => e.stopPropagation()}>
              {value}
            </a>
          </Tooltip>
        );
      }
    },
    {
      title: '동작',
      width: 100,
      align: 'center',
      render: (record) => {
        if (record.mime === 'prev' || record.isDirectory) return null;
        return (
          <Button
            danger
            size="small"
            onClick={(e) => {
              e.stopPropagation();

              openConfirmModal({
                onOk: async () => {
                  try {
                    const { data } = await fileApi.deleteFile(record.id);

                    if (data.success) {
                      closeConfirmModal();
                      await fetchFileList();
                      message.success('파일 삭제에 성공했습니다.');
                    } else {
                      throw new Error('파일 삭제에 실패했습니다.');
                    }
                  } catch (e) {
                    console.error(e);
                    message.error('파일 삭제에 실패했습니다.');
                  }
                }
              });
            }}
          >
            삭제
          </Button>
        );
      }
    }
  ];

  React.useEffect(() => {
    fetchFileList();
  }, [fetchFileList]);

  return (
    <>
      <DefaultLayout>
        <PageHeader title="파일 관리" subTitle="파일 및 폴더를 관리합니다." style={{ margin: '16px 20px 0px 20px' }} />
        <Divider />
        <Content style={{ padding: '0 24px', margin: '0px 20px', marginBottom: '44px' }}>
          <Row gutter={[16, 16]}>
            <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                onClick={() =>
                  openCreateModal({
                    title: '폴더 생성',
                    transitionName: ''
                  })
                }
                style={{ marginRight: '16px' }}
              >
                폴더 생성
              </Button>
              <Button
                onClick={() =>
                  openUploadModal({
                    title: '파일 업로드',
                    transitionName: ''
                  })
                }
              >
                파일 업로드
              </Button>
            </Col>
            <Col span={24}>
              <Table
                dataSource={fileList.value?.data.data || []}
                columns={columns}
                pagination={false}
                loading={fileList.loading}
                onRow={(record) => ({
                  onClick: () => {
                    if (actionLoadingRef.current) return;
                    actionLoadingRef.current = true;

                    setCurrentFileId(record.id || '');
                    setCurrentMetadata(record.metaData || '');
                    if (record.isDirectory) {
                      setSearchParams((prev) => {
                        return {
                          directory: prev.get('directory')
                            ? `${prev.get('directory')}/${record.name}`
                            : record.name || ''
                        };
                      });
                    } else if (record.mime === 'prev') {
                      setSearchParams((prev) => {
                        if (!Array.isArray(prev.get('directory')?.split('/'))) {
                          return new URLSearchParams();
                        }
                        return {
                          directory: `${prev
                            .get('directory')
                            ?.split('/')
                            .filter((item) => item)
                            .slice(0, (prev.get('directory')?.split('/').length || 0) - 1)
                            .join('/')}`
                        };
                      });
                    } else {
                      openMetadataModal({});

                      actionLoadingRef.current = false;
                    }
                  }
                })}
                rowKey="name"
              />
            </Col>
          </Row>
        </Content>
      </DefaultLayout>
      <CreateFolderModal modalData={createModal} closeModal={closeCreateModal} refetch={fetchFileList} />
      <FileUploadModal
        modalData={uploadModal}
        closeModal={closeUploadModal}
        currentDirectory={directory}
        refetch={fetchFileList}
      />
      <ConfirmDeleteModal modalData={confirmModal} />
      <CreateOrModifyMetadataModal
        modalData={metadataModal}
        closeModal={closeMetadataModal}
        fileId={currentFileId}
        metadata={currentMetadata}
        refetch={fetchFileList}
      />
    </>
  );
}
