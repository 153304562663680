import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, ModalProps, Upload, message } from 'antd';
import React from 'react';
import { HeaderThumbnailResponseModel } from '@uniquegood/realworld-admin-interface';
import { fileApi, headerThumbnailApi } from '@src/apis/admin';

type CreateModalProps = {
  modalData: ModalProps;
  initialData?: HeaderThumbnailResponseModel;
  closeModal: () => unknown;
  groupId: string;
  refetch: () => Promise<unknown>;
};

type FormData = {
  titleImageUrl: {
    file: File;
    fileList: FileList & { uid: string; url: string }[];
  };
  backgroundImageUrl: {
    file: File;
    fileList: FileList & { uid: string; url: string }[];
  };
  subTitle: string;
  linkUrl: string;
  webLinkUrl: string;
  order?: number;
};

export default function ModifyModal({ modalData, initialData, closeModal, groupId, refetch }: CreateModalProps) {
  const [form] = Form.useForm();

  const handleSubmit = async (values: FormData) => {
    try {
      let titleImageUrl;

      if (values.titleImageUrl.fileList.length > 0 && values.titleImageUrl.fileList[0].uid.includes('prev')) {
        titleImageUrl = values.titleImageUrl?.fileList[0].url;
      } else if (
        values.titleImageUrl.file &&
        (values.titleImageUrl.file as File & { status: string }).status !== 'removed'
      ) {
        const {
          data: {
            data: { url }
          }
        } = await fileApi.uploadFile(undefined, undefined, values.titleImageUrl.file);

        titleImageUrl = url;
      }

      const {
        data: {
          data: { url: backgroundImageUrl }
        }
      } = values.backgroundImageUrl.fileList[0].uid.includes('prev')
        ? { data: { data: { url: values.backgroundImageUrl.fileList[0].url } } }
        : await fileApi.uploadFile(undefined, undefined, values.backgroundImageUrl.file);

      const { data } = await headerThumbnailApi.editHeaderThumbnail(groupId, initialData?.id || '', {
        ...values,
        subtitle: values.subTitle,
        titleImageUrl,
        backgroundImageUrl: backgroundImageUrl || '',
        order: initialData?.order || 0
      });

      if (data.success) {
        message.success('썸네일을 수정했습니다.');

        await refetch();

        closeModal();
      }
    } catch (e) {
      console.error(e);
      message.error('썸네일 수정에 실패했습니다.');
    }
  };

  React.useEffect(() => {
    if (!initialData) return;

    form.setFieldsValue({
      ...initialData,
      subTitle: initialData?.subtitle,
      titleImageUrl: initialData?.titleImageUrl
        ? {
            file: null,
            fileList: [{ uid: 'prev-image', name: '이전 이미지', url: initialData?.titleImageUrl }]
          }
        : null,
      backgroundImageUrl: {
        file: null,
        fileList: [{ uid: 'prev-image', name: '이전 이미지', url: initialData?.backgroundImageUrl }]
      }
    });
  }, [initialData]);

  return (
    <Modal {...modalData} title="메인 썸네일 수정" okText="확인" cancelText="닫기" onOk={form.submit}>
      <Form
        form={form}
        onFinish={handleSubmit}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        validateMessages={{ required: '필수 입력 항목입니다.' }}
      >
        <Form.Item name="backgroundImageUrl" label="배경 이미지" rules={[{ required: true }]}>
          <Upload
            defaultFileList={[{ uid: `prev-image`, name: '이전 이미지', url: initialData?.backgroundImageUrl }]}
            maxCount={1}
            beforeUpload={() => false}
            listType="picture"
          >
            <Button icon={<UploadOutlined />}>이미지 업로드</Button>
          </Upload>
        </Form.Item>
        <Form.Item name="linkUrl" label="앱 링크 URL" rules={[{ required: true }]}>
          <Input placeholder="클릭 시 이동할 주소를 입력해주세요." />
        </Form.Item>
        <Form.Item name="webLinkUrl" label="웹 링크 URL" rules={[{ required: true }]}>
          <Input placeholder="클릭 시 이동할 주소를 입력해주세요." />
        </Form.Item>
        <Form.Item name="titleImageUrl" label="제목 이미지" shouldUpdate>
          <Upload
            defaultFileList={
              initialData?.titleImageUrl
                ? [{ uid: `prev-image`, name: '이전 이미지', url: initialData?.titleImageUrl }]
                : undefined
            }
            maxCount={1}
            beforeUpload={() => false}
            onRemove={() => {
              form.setFieldsValue({
                titleImageUrl: undefined
              });
            }}
            listType="picture"
          >
            <Button icon={<UploadOutlined />}>이미지 업로드</Button>
          </Upload>
        </Form.Item>
        <Form.Item name="subTitle" label="부제목">
          <Input placeholder="부제목을 입력해주세요." />
        </Form.Item>
      </Form>
    </Modal>
  );
}
