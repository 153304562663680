import { AmuletGradeCreateOrUpdateRequestModel } from '@uniquegood/realworld-admin-interface';
import { Form, Input, InputNumber, Modal, ModalProps, message } from 'antd';
import React from 'react';
import { amuletApi } from '@src/apis/admin';
import useModalState from '@src/hooks/useModalState';

interface ModifyGradeModalProps {
  modalData: ModalProps;
  closeModal: () => unknown;
  gradeList: AmuletGradeCreateOrUpdateRequestModel[];
  originData: AmuletGradeCreateOrUpdateRequestModel & { gradeId: string };
  refetch: () => unknown;
  refetchGrade: () => unknown;
}

export default function ModifyGradeModal({
  modalData,
  closeModal,
  gradeList,
  originData,
  refetch,
  refetchGrade
}: ModifyGradeModalProps) {
  const totalWeight = gradeList.reduce((acc, cur) => (cur.name !== originData.name ? acc + cur.weight : acc), 0);

  const [form] = Form.useForm();

  const { openModal: openConfirmModal, closeModal: closeConfirmModal, modal: confirmModal } = useModalState();

  const handleFinish = async (value: AmuletGradeCreateOrUpdateRequestModel) => {
    try {
      const { data } = await amuletApi.updateAmuletGrade(originData.gradeId, value);

      if (data.success) {
        await refetch();
        await refetchGrade();
        closeModal();
        closeConfirmModal();
        message.success('부적 등급이 수정되었습니다.');
      }
    } catch (e) {
      console.error(e);
      message.error('부적 등급 수정에 실패했습니다.');
    }
  };

  React.useEffect(() => {
    form.setFieldsValue({
      ...originData
    });
  }, [form, originData]);

  return (
    <>
      <Modal {...modalData} title="부적 등급 수정" onOk={() => openConfirmModal({})} okText="확인" cancelText="닫기">
        <Form form={form} onFinish={handleFinish}>
          <Form.Item label="등급 이름" name="name" rules={[{ required: true, message: '등급 이름을 입력해주세요.' }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="가중치"
            name="weight"
            rules={[
              {
                required: true,
                message: '가중치를 입력해주세요.'
              },
              {
                type: 'number',
                message: '숫자만 입력해주세요.'
              }
            ]}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            label="뒷면 이미지"
            name="imageUrl"
            rules={[{ required: true, message: '이미지 URL을 입력해주세요.' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="아이콘 이미지"
            name="iconImageUrl"
            rules={[{ required: true, message: '아이콘 URL을 입력해주세요.' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <Modal {...confirmModal} onOk={() => form.submit()} okText="확인" cancelText="닫기">
        <p>부적 등급을 수정하시겠습니까?</p>
        <p>부적 등급을 수정하면 기존 부적 등급의 확률이 변경됩니다.</p>
        <p>변경 예정 확률</p>
        {gradeList.map((grade) => (
          <div key={grade.name}>
            {grade.name}:{' '}
            {grade.name === originData.name
              ? ((form.getFieldValue('weight') / (totalWeight + form.getFieldValue('weight'))) * 100).toFixed(5)
              : ((grade.weight / (totalWeight + form.getFieldValue('weight'))) * 100).toFixed(5)}
            %
          </div>
        ))}
      </Modal>
    </>
  );
}
