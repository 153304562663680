import React, { useState } from 'react';
import { useAsyncFn } from 'react-use';
import { Button, Checkbox, DatePicker, Divider, Form, Input, notification, Radio, RadioChangeEvent, Space } from 'antd';
import { PageHeader } from '@ant-design/pro-components';
import { Content } from 'antd/es/layout/layout';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Dayjs } from 'dayjs';
import { CreatePushViewModel } from '@uniquegood/realworld-admin-interface';
import DefaultLayout from '@src/components/DefaultLayout';
import { pushApi } from '@src/apis/admin';

const { TextArea } = Input;

function PushSendPage() {
  const queryStringData = new URLSearchParams(window.location.search);
  const userId = queryStringData.get('userId');

  const [isIndividual, setIsIndividual] = useState(!!userId);
  const [isScheduled, setIsScheduled] = useState(false);

  const [form] = Form.useForm();

  React.useEffect(() => {
    if (isIndividual) {
      form.setFieldsValue({
        type: 'Individual',
        userIds: userId
      });

      window.history.replaceState(null, '', window.location.origin + window.location.pathname);
    }
  }, []);

  const [, onPushSave] = useAsyncFn(async (data: CreatePushViewModel) => {
    try {
      await pushApi.push(data);

      notification.success({
        message: '저장 완료!',
        description: '푸시알림이 성공적으로 저장되었습니다.',
        placement: 'bottomRight'
      });
    } catch (error) {
      notification.error({
        message: '저장 실패',
        description: '푸시알림 저장에 실패했습니다.',
        placement: 'bottomRight'
      });
    }
  }, []);

  interface FormFields extends Omit<CreatePushViewModel, 'sendTime'> {
    sendTime?: Dayjs;
  }

  function onSave(fields: FormFields) {
    onPushSave({ ...fields, sendTime: fields.sendTime?.format() });
  }

  return (
    <DefaultLayout>
      <PageHeader title="푸시 보내기" subTitle="새 푸시 메시지를 보냅니다." style={{ margin: '16px 20px 0px 20px' }} />
      <Divider />
      <Content style={{ padding: '0px 24px', margin: '0px 20px' }}>
        <Form<FormFields>
          form={form}
          layout="vertical"
          onFinish={onSave}
          style={{
            padding: '18px 24px 4px 24px',
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: 'initial'
          }}
        >
          <Form.Item name="type" label="푸시 종류" rules={[{ required: true, message: '푸시 종류를 선택하세요!' }]}>
            <Radio.Group
              onChange={(e: RadioChangeEvent) => {
                if (e.target.value === 'Individual') {
                  setIsIndividual(true);
                } else setIsIndividual(false);
              }}
            >
              <Space direction="vertical">
                <Radio value="Notification">공지 푸시 (전체 사용자에게 전송)</Radio>
                <Radio value="Marketing">마케팅 푸시 (주간/야간 마케팅 푸시 수신에 동의한 사용자에게 전송)</Radio>
                <Radio value="Individual">개인 푸시</Radio>
                {isIndividual && (
                  <Form.Item
                    name="userIds"
                    label="푸시를 받을 사용자 ID"
                    extra="사용자 ID는 [사용자 관리] > [사용자 목록]에서 복사할 수 있습니다. 사용자 ID가 여러 개인 경우 줄바꿈(엔터)으로 구분해 주세요."
                  >
                    <TextArea rows={4} size="small" disabled={!isIndividual} required allowClear />
                  </Form.Item>
                )}
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="제목" name="title" rules={[{ required: true, message: '제목을 설정하세요!' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="내용 텍스트" name="content" rules={[{ required: true, message: '내용을 설정하세요!' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="링크 URL" name="url">
            <Input placeholder="링크 URL" />
          </Form.Item>
          <Form.Item label="이미지 URL" name="image">
            <Input placeholder="이미지 URL" />
          </Form.Item>
          <Space align="start">
            <Checkbox
              onChange={(e: CheckboxChangeEvent) => {
                setIsScheduled(e.target.checked);
              }}
            >
              전송 예약
            </Checkbox>
            {isScheduled && (
              <Form.Item name="sendTime">
                <DatePicker showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm" />
              </Form.Item>
            )}
          </Space>

          <Form.Item style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button type="primary" htmlType="submit">
              저장
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </DefaultLayout>
  );
}

export default PushSendPage;
