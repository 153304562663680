import { PageHeader } from '@ant-design/pro-components';
import { Button, Divider, Layout, Table, Tag } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { AmuletAchievementState, AmuletAchievementResponseModel } from '@uniquegood/realworld-admin-interface';
import { useAsyncFn } from 'react-use';
import { ColumnsType } from 'antd/es/table';
import DefaultLayout from '@src/components/DefaultLayout';
import { amuletApi } from '@src/apis/admin';
import { AmuletFilter } from '@src/components/AmuletFilter';
import useModalState from '@src/hooks/useModalState';
import ChangeStateModal from './ChangeStateModal';

export default function Achievements() {
  const { openModal, closeModal, modal } = useModalState();

  const [username, setUsername] = React.useState('');
  const [amuletAchievementState, setAmuletAchievementState] = React.useState<AmuletAchievementState>();
  const [amuletAchievementName, setAmuletAchievementName] = React.useState('');
  const [currentPage, setCurrentPage] = React.useState(1);

  const [currentUserAchievementId, setCurrentUserAchievementId] = useState<string>('');

  const [getAmuletListApi, fetchAmuletListApi] = useAsyncFn(async () => {
    try {
      const { data } = await amuletApi.getAmuletAchievementList(
        username,
        amuletAchievementState,
        amuletAchievementName,
        currentPage, // page
        10 // take
      );

      const list = data.data;

      if (list) {
        return {
          ...list
        };
      }
    } catch (err) {
      console.error(err);
    }
    return null;
  }, [username, amuletAchievementState, amuletAchievementName, currentPage]);

  const columns: ColumnsType<AmuletAchievementResponseModel> = [
    {
      title: '유저 아이디',
      dataIndex: 'userId',
      key: 'userId'
    },
    {
      title: '유저 이름',
      dataIndex: 'userName',
      key: 'userName'
    },
    {
      title: '유저 업적 아이디',
      dataIndex: 'userAmuletAchievementId',
      key: 'userAmuletAchievementId'
    },
    {
      title: '업적 아이디',
      dataIndex: 'amuletAchievementId',
      key: 'amuletAchievementId'
    },
    {
      title: '업적 이름',
      dataIndex: 'amuletAchievementName',
      key: 'amuletAchievementName'
    },
    {
      title: '업적 내용',
      dataIndex: 'amuletAchievementDescription',
      key: 'amuletAchievementDescription'
    },

    {
      title: '업적 상태',
      dataIndex: 'amuletAchievementState',
      key: 'amuletAchievementState',
      render: (stateLabel: string) => {
        switch (stateLabel) {
          case 'Attainment':
            return <Tag color="red">달성</Tag>;
          case 'RewardRequest':
            return <Tag color="gray">보상 요청</Tag>;
          case 'RewardComplete':
            return <Tag color="#c869ff">보상 완료</Tag>;
          default:
            return <Tag>미달성</Tag>;
        }
      }
    },
    {
      title: '동작',
      render: (record) => {
        return (
          <Button
            onClick={() => {
              setCurrentUserAchievementId(record.userAmuletAchievementId);
              openModal({});
            }}
            size="small"
            htmlType="button"
            style={{ fontSize: '12px' }}
          >
            상태 변경
          </Button>
        );
      }
    }
  ];

  useEffect(() => {
    fetchAmuletListApi();
  }, [fetchAmuletListApi]);

  const source = getAmuletListApi.value?.data?.map(
    (item, index) =>
      ({
        key: index,
        userId: item.userId,
        userName: item.userName,
        userAmuletAchievementId: item.userAmuletAchievementId,
        amuletAchievementId: item.amuletAchievementId,
        amuletAchievementName: item.amuletAchievementName,
        amuletAchievementDescription: item.amuletAchievementDescription,
        amuletAchievementState: item.amuletAchievementState
      } as unknown as AmuletAchievementResponseModel)
  );

  return (
    <>
      <DefaultLayout>
        <Layout>
          <PageHeader
            title="업적 관리"
            subTitle="다구 부적의 업적을 관리합니다."
            style={{ margin: '16px 20px 0px 20px' }}
          />
          <Divider />

          <Content style={{ padding: '0px 24px', margin: '0px 20px' }}>
            {/* 필터 자리 */}
            <AmuletFilter
              refetch={{
                setUsername,
                setAmuletAchievementName,
                setAmuletAchievementState,
                setCurrentPage
              }}
            />
            <Table<AmuletAchievementResponseModel>
              dataSource={source}
              columns={columns}
              rowKey={(record) =>
                typeof record.userAmuletAchievementId === 'string' ? record.userAmuletAchievementId : ''
              }
              loading={getAmuletListApi.loading}
              pagination={{
                current: currentPage,
                total: getAmuletListApi.value?.count,
                // getAmuletListApi.value?.count,
                pageSize:
                  // getAmuletListApi.value?.totalPages,
                  10,
                onChange: (page) => {
                  setCurrentPage(page);
                }
              }}
              scroll={{ y: 600 }}
            />
          </Content>
        </Layout>
      </DefaultLayout>
      <ChangeStateModal
        modalData={modal}
        currentId={currentUserAchievementId}
        closeModal={closeModal}
        refetch={fetchAmuletListApi}
      />
    </>
  );
}
