import { Modal, ModalProps, Table } from 'antd';
import { useAsync } from 'react-use';
import { ColumnsType } from 'antd/es/table';
import { RedeemCodeListResponseModel } from '@uniquegood/realworld-admin-interface';
import { redeemApi } from '@src/apis/admin';

interface UnlockCodeModalProps {
  modalData: ModalProps;
  data: object[];
  selectedProjectId: string;
}

export default function UnlockCodeModal({ modalData, selectedProjectId }: UnlockCodeModalProps) {
  const unlockCodeList = useAsync(async () => {
    const { data } = await redeemApi.getRedeemCodes('Project', selectedProjectId);

    return data.data;
  }, [selectedProjectId]);

  const columns: ColumnsType<RedeemCodeListResponseModel> = [
    {
      key: 'code',
      dataIndex: 'code',
      title: '코드'
    },
    {
      key: 'note',
      dataIndex: 'note',
      title: '메모'
    },
    {
      key: 'expiresAt',
      dataIndex: 'expiresAt',
      title: '만료일시'
    },
    {
      key: 'usedCount',
      dataIndex: 'usedCount',
      title: '사용 개수',
      render: (value, record) => {
        return record.count === null ? value : `${value} / ${record.count}`;
      }
    }
  ];
  return (
    <Modal {...modalData} title="언락 코드 목록" transitionName="" destroyOnClose width={1000}>
      <Table dataSource={unlockCodeList.value} columns={columns} loading={unlockCodeList.loading} />
    </Modal>
  );
}
