import React from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import styled from '@emotion/styled';
import toast from 'react-hot-toast';
import { usePostLoginMutation } from '@src/apis/auth/mutations';
import { setLocalStorage } from '@src/utils/localStorage';
import { RWPayload } from '@src/types';

export default function Form() {
  const navigate = useNavigate();

  const { mutateAsync: postLogin, isPending: isLoginPending } = usePostLoginMutation();

  const [value, setValue] = React.useState({
    email: '',
    password: ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue({
      ...value,
      [e.target.id]: e.target.value
    });
  };

  const handleSubmit = async () => {
    // TODO: 로그인 처리
    try {
      if (isLoginPending) return;

      const data = await postLogin({ id: value.email, password: value.password });

      const accessToken = data.token;
      const decoded = jwtDecode<RWPayload>(accessToken);
      const { rwRole } = decoded;

      if (rwRole !== 'RealWorld.Roles.Admin') {
        throw new Error('No Admin Role');
      }

      setLocalStorage('accessToken', data.token);
      setLocalStorage('refreshToken', data.refreshToken);

      navigate('/dashboard', { replace: true });
    } catch (e) {
      if (e instanceof Error) {
        switch (e.message) {
          case 'No Admin Role':
            toast.error('관리자 권한이 없습니다.');
            break;
          default:
            toast.error('로그인에 실패했습니다.');
            break;
        }
      }
    }
  };

  return (
    <FormContainer
      onSubmit={(e) => {
        e.preventDefault();

        handleSubmit();
      }}
    >
      <Input
        id="email"
        type="text"
        value={value.email}
        onChange={handleChange}
        placeholder="아이디"
        style={{ marginBottom: '12px' }}
      />
      <Input id="password" type="password" value={value.password} onChange={handleChange} placeholder="비밀번호" />
      <Button type="submit">로그인</Button>
    </FormContainer>
  );
}

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Input = styled.input`
  width: 320px;
  height: 40px;
  padding: 0 12px;
  border-radius: 8px;
  border: 1px solid #e9ebf1;
  color: #1a1a1a;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;

  &::placeholder {
    color: #b2b6c2;
  }
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 48px;
  padding: 0 16px;
  margin-top: 12px;
  border: none;
  border-radius: 8px;
  background-color: #7800ff;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #5500b7;
  }
`;
