import { PageHeader } from '@ant-design/pro-components';
import { Col, Divider, Row, Table } from 'antd';
import { Content } from 'antd/es/layout/layout';
import Title from 'antd/es/typography/Title';
import Paragraph from 'antd/es/typography/Paragraph';
import { css } from '@emotion/react';
import { useAsync } from 'react-use';
import { ColumnsType } from 'antd/es/table';
import { GamePlayHistoryResponseModel, ProjectStatus } from '@uniquegood/realworld-admin-interface';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import DefaultLayout from '@src/components/DefaultLayout';
import { userApi } from '@src/apis/admin';
import { gameStatusToLabel } from '@src/constants/gameStatus';

export default function Usage() {
  const { id } = useParams();

  const usageListData = useAsync(async () => {
    try {
      const { data } = await userApi.getUserUsed(id ?? '');

      return data.data;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }, []);
  const columns: ColumnsType<GamePlayHistoryResponseModel> = [
    {
      key: 'projectId',
      dataIndex: 'projectId',
      title: '프로젝트 ID'
    },
    {
      key: 'name',
      dataIndex: 'name',
      title: '프로젝트 명'
    },
    {
      key: 'paid',
      dataIndex: 'paid',
      title: '결제 여부',
      render: (value) => (value ? '결제' : '무료 또는 언락코드')
    },
    {
      key: 'refunded',
      dataIndex: 'refunded',
      title: '환불 여부',
      render: (value) => (value ? '환불됨' : '-')
    },
    {
      key: 'paidAt',
      dataIndex: 'payedAt',
      title: '구매 일시',
      render: (value) => value && dayjs(value).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      key: 'lastStartedAt',
      dataIndex: 'lastStartedAt',
      title: '마지막 시작 일시',
      render: (value) => value && dayjs(value).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: '게임 배포 상태',
      render: (value: ProjectStatus) => gameStatusToLabel[value]
    }
  ];

  return (
    <DefaultLayout>
      <PageHeader
        title="이용권 조회"
        subTitle="플레이어의 이용권 내역을 조회합니다."
        style={{ margin: '16px 20px 0px 20px' }}
      />
      <Divider />
      <Content style={{ padding: '0 24px', margin: '0px 20px', marginBottom: '44px' }}>
        <Row style={{ marginBottom: '16px' }}>
          <Col css={ColumnItemStyle}>
            <Title level={5}>사용자 정보</Title>
            <Row>
              <Col flex={1}>
                <Paragraph>사용자 이름: {usageListData.value?.userName}</Paragraph>
              </Col>
              <Col flex={1}>
                <Paragraph>사용자 ID: {usageListData.value?.userId}</Paragraph>
              </Col>
              <Col flex={1}>
                <Paragraph>이용권 수: {usageListData.value?.usedProjectCount}</Paragraph>
              </Col>
            </Row>
          </Col>
        </Row>
        <Table dataSource={usageListData.value?.playHistories ?? []} columns={columns} pagination={false} />
      </Content>
    </DefaultLayout>
  );
}

const ColumnItemStyle = css`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 16px;
  border-radius: 16px;
  background-color: #fff;
`;
