import { PageHeader } from '@ant-design/pro-components';
import { Button, Checkbox, Col, Divider, Form, Input, Row, Table, message, notification } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useAsync } from 'react-use';
import React from 'react';
import { ColumnsType } from 'antd/es/table';
import {
  ProjectFilterType,
  ProjectGenreFilterType,
  ProjectsResponseModel
} from '@uniquegood/realworld-admin-interface';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import DefaultLayout from '@src/components/DefaultLayout';
import { applicationProjectApi, projectApi } from '@src/apis/admin';
import useModalState from '@src/hooks/useModalState';
import { STATION_HOST, STUDIO_HOST } from '@src/config';
import { filterTypeToString, genreFilterTypeToString } from '@src/constants/project';
import UnlockCodeModal from './UnlockCodeModal';
import ChangeChannelModal from './ChangeChannelModal';
import SetBadgeModal from './SetBadgeModal';
import ChangeStateModal from './ChangeStateModal';

const { Search } = Input;

export default function Project() {
  const navigate = useNavigate();
  const location = useLocation();
  const isCreatorProject = location.pathname.includes('creator');
  const { id } = useParams();
  const queryData = new URLSearchParams(location.search);

  const [searchKeyword, setSearchKeyword] = React.useState(queryData.get('search') || '');
  const [filter, setFilter] = React.useState<ProjectFilterType[]>(
    (queryData.get('filter')?.split(',') as ProjectFilterType[]) || []
  );
  const [genreFilter, setGenreFilter] = React.useState<ProjectGenreFilterType[]>(
    (queryData.get('genreFilter')?.split(',') as ProjectGenreFilterType[]) || []
  );
  const [currentPage, setCurrentPage] = React.useState(1);
  const [selectedProjectId, setSelectedProjectId] = React.useState('');
  const [loading, setLoading] = React.useState({
    duplicate: false
  });

  const { openModal: openUnlockCodeModal, closeModal: closeUnlockCodeModal, modal: unlockCodeModal } = useModalState();
  const {
    openModal: openChangeChannelModal,
    closeModal: closeChangeChannelModal,
    modal: changeChannelModal
  } = useModalState();
  const { openModal: openSetBadgeModal, closeModal: closeSetBadgeModal, modal: setBadgeModal } = useModalState();
  const {
    openModal: openChangeStateModal,
    closeModal: closeChangeStateModal,
    modal: changeStateModal
  } = useModalState();

  const projectList = useAsync(async () => {
    try {
      if (isCreatorProject) {
        const { data } = await applicationProjectApi.getApplicationProjects(id || '', searchKeyword, currentPage, 15);

        return data.data;
      }
      const { data } = await projectApi.getProjects(searchKeyword, filter, genreFilter, currentPage, 15);

      return data.data;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }, [currentPage, searchKeyword, filter, genreFilter]);

  const handleFilterCheck = (value: CheckboxValueType[]) => {
    setFilter(value as ProjectFilterType[]);

    window.history.pushState(undefined, '', `?search=${searchKeyword}&filter=${value}&genreFilter=${genreFilter}`);
  };

  const handleGenreFilterCheck = (value: CheckboxValueType[]) => {
    setGenreFilter(value as ProjectGenreFilterType[]);

    window.history.pushState(undefined, '', `?search=${searchKeyword}&filter=${filter}&genreFilter=${value}`);
  };

  const handleDuplicateClick = async (value: ProjectsResponseModel) => {
    try {
      notification.info({
        message: '프로젝트 복사중입니다. 잠시만 기다려주세요.'
      });
      setLoading({
        ...loading,
        duplicate: true
      });
      const { data } = await projectApi.projectDuplicate(value.id || '');

      setLoading({
        ...loading,
        duplicate: false
      });
      message.success('복사에 성공했습니다.');
      setSearchKeyword(data.data.projectName);
      window.history.pushState(undefined, '', `?search=${data.data.projectName}`);
    } catch (e) {
      console.error(e);
      notification.error({
        message: '복사에 실패했습니다.'
      });
    }
  };

  const handleCopyClick = async (projectId: string) => {
    try {
      await window.navigator.clipboard.writeText(`realworld://project?projectId=${projectId}`);

      message.success(`[realworld://project?projectId=${projectId}] 스키마가 클립보드에 복사되었습니다.`);
    } catch (e) {
      console.error(e);
      message.error('복사에 실패했습니다. 잠시 후 다시 시도해주세요.');
    }
  };

  const columns: ColumnsType<ProjectsResponseModel> = [
    {
      key: 'id',
      dataIndex: 'id',
      title: 'ID'
    },
    {
      key: 'name',
      dataIndex: 'name',
      title: '이름'
    },
    {
      key: 'description',
      dataIndex: 'description',
      title: '설명'
    },
    {
      key: 'isLockedDefault',
      dataIndex: 'isLockedDefault',
      title: '기본 잠금 여부',
      render: (value) => (value ? '잠금' : '미잠금')
    },
    {
      key: 'isVisibleDefault',
      dataIndex: 'isVisibleDefault',
      title: '기본 노출 여부',
      render: (value) => (value ? '노출' : '미노출')
    },
    {
      title: '참여 (완료) 인원',
      render: (value) => (
        <Button onClick={() => navigate(`/content/project/${value.id}/participant`)}>통계 확인하기</Button>
      )
    },
    {
      key: 'scenarioCount',
      dataIndex: 'scenarioCount',
      title: '시나리오',
      render: (value, record) => <Link to={`./${record.id}/scenario`}>{value}</Link>
    },
    {
      title: '동작',
      render: (value) => {
        return (
          <Row gutter={[4, 4]}>
            <Col>
              <Button
                onClick={() => openUnlockCodeModal({})}
                size="small"
                style={{ backgroundColor: '#31a66a', color: '#fff' }}
              >
                언락코드 조회
              </Button>
            </Col>
            <Col>
              <Button
                onClick={() => navigate(`/creator/channel/${value.applicationId}`)}
                size="small"
                style={{ backgroundColor: '#31a66a', color: '#fff' }}
              >
                채널 정보
              </Button>
            </Col>
            <Col>
              <Button
                onClick={() => openChangeChannelModal({})}
                size="small"
                style={{ backgroundColor: '#eca909', color: '#fff' }}
              >
                채널 변경
              </Button>
            </Col>
            <Col>
              <Button
                onClick={() => openSetBadgeModal({})}
                size="small"
                style={{ backgroundColor: '#eca909', color: '#fff' }}
              >
                배지 설정
              </Button>
            </Col>
            <Col>
              <Button
                onClick={() => handleDuplicateClick(value)}
                size="small"
                style={{ backgroundColor: '#eca909', color: '#fff' }}
                loading={loading.duplicate}
              >
                게임 복사
              </Button>
            </Col>
            <Col>
              <Button
                onClick={() => handleCopyClick(value.id)}
                size="small"
                style={{ backgroundColor: '#eca909', color: '#fff' }}
                loading={loading.duplicate}
              >
                스키마 복사
              </Button>
            </Col>
            <Col>
              <Button
                onClick={() => openChangeStateModal({})}
                size="small"
                style={{ backgroundColor: '#eca909', color: '#fff' }}
              >
                미공개 처리
              </Button>
            </Col>
            <Col>
              <Button size="small" style={{ backgroundColor: '#c869ff', color: '#fff' }}>
                <a
                  href={
                    value.projectEngineVersion === 'V1'
                      ? `https://${STUDIO_HOST}/apps/${value.applicationId}/projects/${value.id}`
                      : `https://${STATION_HOST}/${value.applicationId}/game/${value.id}/dashboard`
                  }
                  target="blank"
                  style={{ all: 'unset' }}
                >
                  스튜디오 열기
                </a>
              </Button>
            </Col>
          </Row>
        );
      }
    }
  ];

  React.useEffect(() => {
    const handleSearchKeyword = () => {
      setSearchKeyword(new URLSearchParams(window.location.search).get('search') || '');
    };

    window.addEventListener('popstate', handleSearchKeyword);

    return () => {
      window.removeEventListener('popstate', handleSearchKeyword);
    };
  }, []);

  React.useEffect(() => {
    const handlePopState = () => {
      const queryData = new URLSearchParams(window.location.search);

      setSearchKeyword(queryData.get('search') || '');
      setFilter((queryData.get('filter')?.split(',') as ProjectFilterType[]) || []);
      setGenreFilter((queryData.get('genreFilter')?.split(',') as ProjectGenreFilterType[]) || []);
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return (
    <>
      <DefaultLayout>
        <PageHeader title="프로젝트 관리" subTitle="프로젝트를 관리합니다." style={{ margin: '16px 20px 0px 20px' }} />
        <Divider />
        <Content style={{ padding: '0 24px', margin: '0px 20px', marginBottom: '44px' }}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form
                fields={[
                  {
                    name: 'search',
                    value: searchKeyword
                  },
                  {
                    name: 'releaseType',
                    value: filter
                  },
                  {
                    name: 'realworldType',
                    value: genreFilter
                  }
                ]}
              >
                <Form.Item name="search">
                  <Search
                    onSearch={(value) => {
                      setSearchKeyword(value);
                      setCurrentPage(1);

                      window.history.pushState(
                        undefined,
                        '',
                        `?search=${value}&filter=${filter}&genreFilter=${genreFilter}`
                      );
                    }}
                    enterButton
                    style={{ width: '40%' }}
                  />
                </Form.Item>
                {!isCreatorProject && (
                  <Row align="middle">
                    <Form.Item name="realworldType" style={{ marginBottom: 0 }}>
                      <Checkbox.Group
                        options={Object.keys(ProjectGenreFilterType).map((genreFilterType) => ({
                          label: genreFilterTypeToString[genreFilterType as ProjectGenreFilterType],
                          value: genreFilterType
                        }))}
                        onChange={handleGenreFilterCheck}
                      />
                    </Form.Item>
                    <div style={{ marginRight: '8px' }}>
                      <Divider type="vertical" />
                    </div>
                    <Form.Item name="releaseType" style={{ marginBottom: 0 }}>
                      <Checkbox.Group
                        options={Object.keys(ProjectFilterType).map((filterType) => ({
                          label: filterTypeToString[filterType as ProjectFilterType],
                          value: filterType
                        }))}
                        onChange={handleFilterCheck}
                      />
                    </Form.Item>
                  </Row>
                )}
              </Form>
            </Col>
            <Col span={24}>
              <Table
                dataSource={projectList.value?.data || []}
                columns={columns}
                loading={projectList.loading}
                scroll={{
                  x: 1400
                }}
                pagination={{
                  current: currentPage,
                  total: projectList.value?.count || 0,
                  pageSize: 15,
                  showSizeChanger: false,
                  onChange: (page) => setCurrentPage(page)
                }}
                onRow={(value) => {
                  return {
                    onClick: () => {
                      setSelectedProjectId(value.id || '');
                    }
                  };
                }}
                rowKey="id"
              />
            </Col>
          </Row>
        </Content>
      </DefaultLayout>
      {unlockCodeModal.open && (
        <UnlockCodeModal modalData={unlockCodeModal} data={[]} selectedProjectId={selectedProjectId} />
      )}
      <ChangeChannelModal
        modalData={changeChannelModal}
        selectedProjectId={selectedProjectId}
        closeModal={closeChangeChannelModal}
      />
      <SetBadgeModal modalData={setBadgeModal} selectedProjectId={selectedProjectId} closeModal={closeSetBadgeModal} />
      <ChangeStateModal
        modalData={changeStateModal}
        selectedProjectId={selectedProjectId}
        closeModal={closeChangeStateModal}
      />
    </>
  );
}
