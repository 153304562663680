import { PageHeader } from '@ant-design/pro-components';
import { Content } from 'antd/es/layout/layout';
import { Divider, Table, Input, Tooltip, Button } from 'antd';
import { useAsync } from 'react-use';
import React from 'react';
import { DropoutReason, DropoutUsersResponseModel } from '@uniquegood/realworld-admin-interface';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import DefaultLayout from '@src/components/DefaultLayout';
import { userApi } from '@src/apis/admin';
import { dropoutReasonToLabel } from '@src/constants/dropoutReason';

const { Search } = Input;

export default function QuitUser() {
  const navigate = useNavigate();
  const [searchKeyword, setSearchKeyword] = React.useState('');
  const [currentPage, setCurrentPage] = React.useState(1);

  const quitUserApiState = useAsync(async () => {
    const { data } = await userApi.dropoutsUsers(searchKeyword, currentPage, 30);

    return data.data;
  }, [searchKeyword, currentPage]);

  const columns: ColumnsType<DropoutUsersResponseModel> = [
    {
      key: 'userId',
      dataIndex: 'userId',
      title: '플레이어 ID'
    },
    {
      key: 'userName',
      dataIndex: 'userName',
      title: '플레이어 이름'
    },
    {
      key: 'withdrawnAt',
      dataIndex: 'withdrawnAt',
      title: '탈퇴 시작 일자',
      render: (value) => {
        const date = dayjs(value);

        return value && date.format('YYYY-MM-DD HH:mm:ss');
      }
    },
    {
      key: 'withdrawnFinishedAt',
      dataIndex: 'withdrawnFinishedAt',
      title: '탈퇴 완료 일자',
      render: (value) => {
        const date = dayjs(value);

        return value && date.format('YYYY-MM-DD HH:mm:ss');
      }
    },
    {
      key: 'dropoutReason',
      dataIndex: 'dropoutReason',
      title: '탈퇴 사유',
      render: (value: DropoutReason, record) => {
        return (
          <Tooltip placement="top" title={record.note}>
            {dropoutReasonToLabel[value]}
          </Tooltip>
        );
      }
    },
    {
      title: '플레이내역 조회',
      render: (value, record) => (
        <Button size="middle" onClick={() => navigate(`/user/playLog/${record.userId}`)}>
          조회하기
        </Button>
      )
    }
  ];

  return (
    <DefaultLayout>
      <PageHeader
        title="탈퇴유저 목록"
        subTitle="탈퇴유저 목록을 조회합니다."
        style={{ margin: '16px 20px 0px 20px' }}
      />
      <Divider />

      <Content style={{ padding: '0 24px', margin: '0px 20px' }}>
        <Search
          placeholder="검색어를 입력하세요."
          onSearch={(value) => {
            setSearchKeyword(value);
            setCurrentPage(1);
          }}
          enterButton
          style={{ marginBottom: '16px', width: '40%' }}
        />
        <Table
          loading={quitUserApiState.loading}
          dataSource={quitUserApiState.value?.data || []}
          columns={columns}
          pagination={{
            pageSize: 30,
            onChange: (page) => {
              setCurrentPage(page);
            },
            total: quitUserApiState.value?.count,
            showSizeChanger: false
          }}
          scroll={{
            scrollToFirstRowOnChange: true,
            y: 500
          }}
          rowKey="userId"
        />
      </Content>
    </DefaultLayout>
  );
}
