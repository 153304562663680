import { ActionSchemaType, PromotionType } from '@uniquegood/realworld-admin-interface/dist';
import { Badge, Space, Table, Tag, Typography } from 'antd';
import dayjs from 'dayjs';
import { useAsync } from 'react-use';
import { PromotionTypeLabel, SchemaTypeLabel } from '@src/constants/point';
import { promotionApi } from '@src/apis/admin';

interface PromotionDetailModalProps {
  promotionId: string;
}

export function PromotionDetailModal({ promotionId }: PromotionDetailModalProps) {
  const promotionDetailApiState = useAsync(async () => {
    const { data } = await promotionApi.getPromotion(promotionId);

    return [
      {
        ...data.data,
        key: data.data?.promotionId,
        period:
          data.data?.startedAt !== null && data.data?.endedAt !== null
            ? `${dayjs(data.data?.startedAt).format('YYYY-MM-DD')} ~ ${dayjs(data.data?.endedAt).format('YYYY-MM-DD')}`
            : '상시',
        createdAt: dayjs(data.data?.createdAt).format('YYYY-MM-DD HH:mm:ss'),
        updatedAt: data.data?.updatedAt ? dayjs(data.data?.updatedAt).format('YYYY-MM-DD HH:mm:ss') : null
      }
    ];
  }, [promotionId]);

  const rewardListColumns = [
    {
      key: 'rewardDescription',
      dataIndex: 'rewardDescription',
      title: '설명'
    },
    {
      key: 'rewardPoint',
      dataIndex: 'rewardPoint',
      title: '츄로'
    },
    {
      key: 'message',
      dataIndex: 'message',
      title: '출력 메시지'
    },
    {
      key: 'isActivate',
      dataIndex: 'isActivate',
      title: '활성화 여부',
      render: (value: boolean) => {
        return (
          <div>{value ? <Badge dot color="green" text="활성화" /> : <Badge dot color="red" text="비활성화" />}</div>
        );
      }
    },
    {
      key: 'secretKey',
      dataIndex: 'secretKey',
      title: '시크릿 키'
    }
  ];

  return (
    <>
      <Table dataSource={promotionDetailApiState.value} pagination={false} loading={promotionDetailApiState.loading}>
        <Table.Column key="title" dataIndex="title" title="프로모션 명" />
        <Table.Column key="enTitle" dataIndex="enTitle" title="프로모션 영문명" />
        <Table.Column
          key="promotionType"
          dataIndex="promotionType"
          title="프로모션 타입"
          render={(value: PromotionType) => {
            switch (value) {
              case PromotionType.Basic:
                return <Tag>{PromotionTypeLabel[value]}</Tag>;
              case PromotionType.Daily:
                return <Tag color="blue">{PromotionTypeLabel[value]}</Tag>;
              case PromotionType.Multi:
                return <Tag color="purple">{PromotionTypeLabel[value]}</Tag>;
              default:
                return null;
            }
          }}
        />
        <Table.Column
          key="schemaType"
          dataIndex="schemaType"
          title="스키마 타입"
          render={(value: ActionSchemaType) => SchemaTypeLabel[value]}
        />
        <Table.Column key="description" dataIndex="description" title="내용" />
      </Table>
      <Table dataSource={promotionDetailApiState.value} pagination={false} loading={promotionDetailApiState.loading}>
        <Table.Column key="period" dataIndex="period" title="기간" />
        <Table.Column key="createdAt" dataIndex="createdAt" title="생성일" />
        <Table.Column key="manager" dataIndex="manager" title="담당자" />
        <Table.Column key="totalPublishPoint" dataIndex="totalPublishPoint" title="총 발행 츄로" />
        <Table.Column key="joinCount" dataIndex="joinCount" title="참여 수" />
      </Table>
      <Table dataSource={promotionDetailApiState.value} pagination={false} loading={promotionDetailApiState.loading}>
        <Table.Column
          key="isActivate"
          dataIndex="isActivate"
          title="활성화 여부"
          render={(value) => {
            return (
              <div>{value ? <Badge dot color="green" text="활성화" /> : <Badge dot color="red" text="비활성화" />}</div>
            );
          }}
        />
        <Table.Column key="updatedAt" dataIndex="updatedAt" title="마지막 수정일" />
        <Table.Column key="lastEditor" dataIndex="lastEditor" title="마지막 수정 관리자" />
      </Table>
      <Space direction="vertical" style={{ width: '100%', marginTop: '16px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography.Title level={5}>보상 목록</Typography.Title>
        </div>
        <Table
          dataSource={promotionDetailApiState.value?.[0].promotionRewards || []}
          columns={rewardListColumns}
          pagination={false}
          scroll={{ y: 300 }}
        />
      </Space>
    </>
  );
}
