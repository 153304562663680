import React from 'react';
import { Avatar, Dropdown, Layout, Menu } from 'antd';
import {
  AuditOutlined,
  CreditCardOutlined,
  DownOutlined,
  UserOutlined,
  DollarCircleOutlined,
  FundProjectionScreenOutlined,
  SettingOutlined,
  DashboardOutlined,
  AlertOutlined,
  DashOutlined,
  HighlightOutlined,
  ProjectOutlined,
  MobileOutlined,
  CrownOutlined,
  MessageOutlined,
  EditOutlined
} from '@ant-design/icons';
import { css, Global } from '@emotion/react';
import { Link, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { pageKeyToPathnameFn } from '@src/constants/page';
import { PageKey } from '@src/models/page';
import { pathnameToSideBarPageKey, sideBarPageKeyLabel } from '@src/constants/sideBar';
import { getLocalStorage, removeLocalStorage } from '@src/utils/localStorage';
import { RWPayload } from '@src/types';

const { Header, Sider, Content } = Layout;

interface LayoutProps {
  children: React.ReactNode;
}

function DefaultLayout({ children }: LayoutProps) {
  const navigate = useNavigate();
  const currentPageKey = pathnameToSideBarPageKey[window.location.pathname];
  const [openKeys, setOpenKeys] = React.useState<string[]>([]);

  const accessToken = getLocalStorage('accessToken');
  const decoded = accessToken ? jwtDecode<RWPayload>(accessToken) : undefined;
  const username = decoded?.rwUserName;
  const userImg = decoded?.rwUserProfile;

  React.useEffect(() => {
    const temp = menuList.find((menu) => menu.children?.some(({ key }) => key === currentPageKey));

    if (temp) {
      setOpenKeys(['dashboard', temp.key]);
    }
  }, []);

  const menuList = React.useMemo(
    () => [
      {
        key: 'DASHBOARD',
        icon: <DashboardOutlined />,
        label: '대시보드',
        onClick: ({ key }: { key: string }) => {
          navigate(pageKeyToPathnameFn[key as PageKey]());
        }
      },
      {
        key: 'user',
        icon: <UserOutlined />,
        label: '플레이어 관리',
        children: ([PageKey.MANAGE_USER, PageKey.PLAY_LOGS, PageKey.USER_STATISTICS, PageKey.QUIT_USERS] as const).map(
          (pageKey) => ({
            key: pageKey,
            label: sideBarPageKeyLabel[pageKey],
            onClick: () => {
              navigate(pageKeyToPathnameFn[pageKey]());
            }
          })
        )
      },
      {
        key: 'creator',
        icon: <HighlightOutlined />,
        label: '크리에이터 관리',
        children: ([PageKey.CREATOR_CHANNEL] as const).map((pageKey) => ({
          key: pageKey,
          label: sideBarPageKeyLabel[pageKey],
          onClick: () => {
            navigate(pageKeyToPathnameFn[pageKey]());
          }
        }))
      },
      {
        key: 'content',
        icon: <ProjectOutlined />,
        label: '콘텐츠 관리',
        children: ([PageKey.CONTENT_PROJECT, PageKey.CONTENT_PREFERENCE] as const).map((pageKey) => ({
          key: pageKey,
          label: sideBarPageKeyLabel[pageKey],
          onClick: () => {
            navigate(pageKeyToPathnameFn[pageKey]());
          }
        }))
      },
      {
        key: 'giftCard',
        icon: <CreditCardOutlined />,
        label: '기프트카드 관리',
        children: ([PageKey.GIFT_CARD_CONTENT_LIST, PageKey.GIFT_CARD_LIST] as const).map((pageKey) => {
          return {
            key: pageKey,
            label: sideBarPageKeyLabel[pageKey],
            onClick: () => {
              navigate(pageKeyToPathnameFn[pageKey]());
            }
          };
        })
      },
      {
        key: 'main',
        icon: <MobileOutlined />,
        label: '메인 관리',
        children: ([PageKey.MAIN_GROUP] as const).map((pageKey) => {
          return {
            key: pageKey,
            label: sideBarPageKeyLabel[pageKey],
            onClick: () => {
              navigate(pageKeyToPathnameFn[pageKey]());
            }
          };
        })
      },
      {
        key: 'notice',
        icon: <AuditOutlined />,
        label: '공지 관리',
        children: (
          [
            PageKey.APP_MAIN_EDIT,
            PageKey.SPLASH_SCREEN,
            PageKey.POP_UPS,
            PageKey.ON_BOARDING,
            PageKey.PUSH_SEND,
            PageKey.PUSH_RESERVATION,
            PageKey.EVENT_PAGE
          ] as const
        ).map((pageKey) => ({
          key: pageKey,
          label: sideBarPageKeyLabel[pageKey],
          onClick: () => {
            navigate(pageKeyToPathnameFn[pageKey]());
          }
        }))
      },
      {
        key: 'community',
        icon: <MessageOutlined />,
        label: '커뮤니티 관리',
        children: ([PageKey.COMMUNITY_TOPIC, PageKey.COMMUNITY_BANNER] as const).map((pageKey) => ({
          key: pageKey,
          label: sideBarPageKeyLabel[pageKey],
          onClick: () => navigate(pageKeyToPathnameFn[pageKey]())
        }))
      },
      {
        key: 'report',
        icon: <AlertOutlined />,
        label: '신고 관리',
        children: (
          [
            PageKey.REPORT_CREATOR_FEED,
            PageKey.REPORT_CREATOR_COMMUNITY,
            PageKey.REPORT_USER_COMMUNITY,
            PageKey.REPORT_REVIEW_AND_HELP
          ] as const
        ).map((pageKey) => ({
          key: pageKey,
          label: sideBarPageKeyLabel[pageKey],
          onClick: () => {
            navigate(pageKeyToPathnameFn[pageKey]());
          }
        }))
      },
      {
        key: 'advertisement',
        icon: <FundProjectionScreenOutlined />,
        label: '광고 시청 관리',
        children: ([PageKey.AD_PROJECT_LIST, PageKey.AD_LIMIT] as const).map((pageKey) => ({
          key: pageKey,
          label: sideBarPageKeyLabel[pageKey],
          onClick: () => {
            navigate(pageKeyToPathnameFn[pageKey]());
          }
        }))
      },
      {
        key: 'point',
        icon: <DollarCircleOutlined />,
        label: '츄로 관리',
        children: (
          [PageKey.POINT_DASHBOARD, PageKey.POINT_LOG, PageKey.POINT_PROMOTION, PageKey.POINT_PROVISION] as const
        ).map((pageKey) => ({
          key: pageKey,
          label: sideBarPageKeyLabel[pageKey],
          onClick: () => {
            navigate(pageKeyToPathnameFn[pageKey]());
          }
        }))
      },
      {
        key: 'dagoo',
        icon: <CrownOutlined />,
        label: '다구 부적 관리',
        children: ([PageKey.DAGOO_AMULET, PageKey.DAGOO_ACHIEVEMENTS] as const).map((pageKey) => ({
          key: pageKey,
          label: sideBarPageKeyLabel[pageKey],
          onClick: () => {
            navigate(pageKeyToPathnameFn[pageKey]());
          }
        }))
      },
      {
        key: 'system',
        icon: <SettingOutlined />,
        label: '시스템 관리',
        children: ([PageKey.SYSTEM] as const).map((pageKey) => ({
          key: pageKey,
          label: sideBarPageKeyLabel[pageKey],
          onClick: () => {
            navigate(pageKeyToPathnameFn[pageKey]());
          }
        }))
      },
      {
        key: 'etc',
        icon: <DashOutlined />,
        label: '기타 관리',
        children: ([PageKey.ETC_SHORT_LINK, PageKey.ETC_FILE] as const).map((pageKey) => ({
          key: pageKey,
          label: sideBarPageKeyLabel[pageKey],
          onClick: () => {
            navigate(pageKeyToPathnameFn[pageKey]());
          }
        }))
      },
      {
        key: 'black-story',
        icon: <EditOutlined />,
        label: '바거스 관리',
        children: ([PageKey.QUIZ_TEMPLATES] as const).map((pageKey) => ({
          key: pageKey,
          label: sideBarPageKeyLabel[pageKey],
          onClick: () => {
            navigate(pageKeyToPathnameFn[pageKey]());
          }
        }))
      }
    ],
    [navigate]
  );

  const userName = getLocalStorage('userName');

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header css={[siteLayoutStyle, headerStyle]}>
        <Link to={pageKeyToPathnameFn[PageKey.LOGIN]()} style={{ textDecoration: 'none' }}>
          <img height="22px" src="/new_logo.png" alt="헤더 로고" />
        </Link>
        <div css={profileContainerStyle}>
          <div>{userName}</div>
          <Dropdown
            menu={{
              items: [
                {
                  key: '1',
                  label: '로그아웃',
                  onClick: () => {
                    removeLocalStorage('userName');
                    removeLocalStorage('userImg');
                    removeLocalStorage('userEmail');
                    removeLocalStorage('token');
                    removeLocalStorage('accessToken');
                    removeLocalStorage('refreshToken');
                    navigate(pageKeyToPathnameFn[PageKey.LOGIN]());
                  }
                }
              ]
            }}
            placement="bottomRight"
          >
            <div style={{ height: '30px', display: 'flex', alignItems: 'center', columnGap: '4px' }}>
              <Avatar alt="프로필 이미지" src={userImg || '/static/images/avatar/1.jpg'} gap={3} />
              <DownOutlined />
            </div>
          </Dropdown>
        </div>
      </Header>
      <Layout>
        <Sider width={200} css={siteLayoutStyle}>
          <Menu
            mode="inline"
            style={{ height: '100%', borderRight: 0, overflow: 'auto' }}
            items={menuList}
            openKeys={openKeys}
            defaultOpenKeys={['dashboard']}
            onOpenChange={setOpenKeys}
            selectedKeys={[currentPageKey]}
          />
        </Sider>
        <Content css={contentContainerStyle}>
          <Layout css={contentLayoutStyle}>{children}</Layout>
        </Content>
      </Layout>
      <Global styles={globalStyle} />
    </Layout>
  );
}

const globalStyle = css`
  .ant-layout-sider-trigger {
    background-color: #ffffff !important;
    color: #000000 !important;
  }
`;

const contentLayoutStyle = css`
  /* min-width: 1100px; */
  height: calc(100vh - 64px);
  overflow-y: auto;
  .ant-layout-content {
    min-height: auto;
  }
`;

const contentContainerStyle = css`
  overflow-x: auto;
`;

const siteLayoutStyle = css`
  background-color: #ffffff !important;
`;

const headerStyle = css`
  padding-inline: 28px !important;
  padding: 0px 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const profileContainerStyle = css`
  height: 30px;
  display: flex;
  flex-direction: row;
  column-gap: 4px;
  align-items: center;
`;

export default DefaultLayout;
