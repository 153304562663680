import { CommunityTopicRequest, TopicType } from '@uniquegood/realworld-admin-interface';
import { Form, Input, Modal, Select, message } from 'antd';
import { ModalProps } from 'antd/lib';
import { communityTopicApi } from '@src/apis/admin';
import { topicTypeToString } from '@src/constants/topic';

interface CreateTopicModalProps {
  modalData: ModalProps;
  closeModal: () => unknown;
  refetch: () => unknown;
}

export default function CreateTopicModal({ modalData, closeModal, refetch }: CreateTopicModalProps) {
  const [form] = Form.useForm();

  const handleSubmit = async (values: CommunityTopicRequest) => {
    try {
      const { data } = await communityTopicApi.createTopic(values);

      if (data.success) {
        message.success('토픽을 생성했습니다.');
        await refetch();
        closeModal();
      }
    } catch (e) {
      console.error(e);
      message.error('토픽 생성에 실패했습니다.');
    }
  };

  return (
    <Modal {...modalData} onOk={form.submit} okText="확인" cancelText="닫기" title="토픽 생성">
      <Form form={form} onFinish={handleSubmit} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} preserve={false}>
        <Form.Item
          name="topicName"
          label="토픽 이름"
          rules={[{ required: true, message: '토픽 이름을 입력해주세요.' }]}
        >
          <Input placeholder="토픽 이름을 입력해주세요." />
        </Form.Item>
        <Form.Item
          name="topicDescription"
          label="토픽 설명"
          rules={[{ required: true, message: '토픽 설명을 입력해주세요.' }]}
        >
          <Input placeholder="토픽 설명을 입력해주세요." />
        </Form.Item>
        <Form.Item
          name="topicType"
          label="토픽 타입"
          rules={[{ required: true, message: '토픽 타입을 선택해주세요.' }]}
        >
          <Select
            placeholder="토픽 타입을 선택해주세요."
            options={Object.values(TopicType).map((item) => ({
              value: item,
              label: topicTypeToString[item]
            }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
