import { PageHeader } from '@ant-design/pro-components';
import { Content } from 'antd/es/layout/layout';
import { Button, Col, DatePicker, Divider, Row, Table } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { RangeValue } from 'rc-picker/lib/interface';
import { Bar } from 'react-chartjs-2';
import { css } from '@emotion/react';
import { useAsync } from 'react-use';
import DefaultLayout from '@src/components/DefaultLayout';
import { userApi } from '@src/apis/admin';
import { downloadExcel } from '@src/utils/excel';

export default function UserStatistics() {
  const [selectedDate, setSelectedDate] = React.useState<RangeValue<Dayjs>>([dayjs().subtract(1, 'month'), dayjs()]);

  const userStatisticsApiState = useAsync(async () => {
    try {
      if (selectedDate === null) return undefined;

      const { data } = await userApi.getUserSignupStatistics(
        selectedDate?.[0]?.format('YYYY-MM') || '',
        selectedDate?.[1]?.format('YYYY-MM') || ''
      );

      return {
        dates: data.data
          ?.map((supItem) => supItem.monthlyDatas?.map((subItem) => `${supItem.year}-${subItem.month}`))
          .flat(),
        counts: data.data?.map((supItem) => supItem.monthlyDatas?.map((subItem) => subItem.count)).flat() || [],
        dropouts: data.data?.map((supItem) => supItem.monthlyDatas?.map((subItem) => subItem.dropouts)).flat() || []
      };
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }, [selectedDate]);

  const dataSource = Array.from({ length: userStatisticsApiState.value?.counts.length ?? 0 }).map((_, i) => ({
    key: i,
    date: userStatisticsApiState.value?.dates?.[i],
    count: userStatisticsApiState.value?.counts[i],
    dropout: userStatisticsApiState.value?.dropouts[i]
  }));

  const handleDownloadClick = () => {
    downloadExcel({
      keys: ['date', 'count', 'dropout'],
      header: { date: '날짜', count: '가입자 수', dropout: '탈퇴자 수' },
      data: [
        ...dataSource.map((item) => ({
          key: String(item.key),
          date: item.date || '',
          count: String(item.count),
          dropout: String(item.dropout)
        })),
        {
          key: 'total',
          date: '총계',
          count: String(
            userStatisticsApiState.value?.counts.reduce((acc, cur) =>
              acc !== undefined && cur !== undefined ? acc + cur : undefined
            )
          ),
          dropout: String(
            userStatisticsApiState.value?.dropouts.reduce((acc, cur) =>
              acc !== undefined && cur !== undefined ? acc + cur : undefined
            )
          )
        }
      ],
      fileName: `사용자 통계-${dataSource[0].date}~${dataSource[dataSource.length - 1].date}`,
      type: 'csv'
    });
  };

  const columns = [
    {
      key: 'date',
      dataIndex: 'date',
      title: '구분'
    },
    {
      key: 'count',
      dataIndex: 'count',
      title: '당월 가입 회원 수'
    },
    {
      key: 'dropout',
      dataIndex: 'dropout',
      title: '당월 탈퇴 회원 수'
    }
  ];

  const tableFooter = () => {
    return (
      <Table.Summary.Row>
        <Table.Summary.Cell index={1}>총계</Table.Summary.Cell>
        <Table.Summary.Cell index={2}>
          {userStatisticsApiState.value?.counts.reduce((acc, cur) =>
            acc !== undefined && cur !== undefined ? acc + cur : undefined
          )}{' '}
          명
        </Table.Summary.Cell>
        <Table.Summary.Cell index={3}>
          {userStatisticsApiState.value?.dropouts.reduce((acc, cur) =>
            acc !== undefined && cur !== undefined ? acc + cur : undefined
          )}{' '}
          명
        </Table.Summary.Cell>
      </Table.Summary.Row>
    );
  };

  return (
    <DefaultLayout>
      <PageHeader
        title="플레이어 통계"
        subTitle="플레이어 통계를 조회합니다."
        style={{ margin: '16px 20px 0px 20px' }}
      />
      <Divider />

      <Content style={{ padding: '0 24px', margin: '0px 20px', marginBottom: '44px' }}>
        <Row gutter={[16, 16]}>
          <Col css={ColumnItemStyle}>
            <div style={{ width: '100%', height: '500px', padding: '16px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <DatePicker.RangePicker
                  picker="month"
                  defaultValue={selectedDate}
                  onChange={(value) => setSelectedDate(value)}
                />
                <Button
                  htmlType="button"
                  onClick={handleDownloadClick}
                  style={{ backgroundColor: '#498a4a', color: '#fff' }}
                >
                  엑셀 다운로드
                </Button>
              </div>
              <Bar
                data={{
                  labels: userStatisticsApiState.value?.dates,
                  datasets: [
                    {
                      label: '가입자 수',
                      data: userStatisticsApiState.value?.counts,
                      backgroundColor: '#c869ff'
                    },
                    {
                      label: '탈퇴자 수',
                      data: userStatisticsApiState.value?.dropouts,
                      backgroundColor: 'gray'
                    }
                  ]
                }}
                options={{
                  maintainAspectRatio: false
                }}
              />
            </div>
          </Col>
          <Col style={{ width: '100%', height: '100%' }}>
            <Table dataSource={dataSource} columns={columns} summary={tableFooter} pagination={false} />
          </Col>
        </Row>
      </Content>
    </DefaultLayout>
  );
}

const ColumnItemStyle = css`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 16px;
  border-radius: 16px;
  background-color: #fff;
`;
