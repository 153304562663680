import { Modal, ModalProps, notification } from 'antd';
import { projectApi } from '@src/apis/admin';

interface ChangeStateModalProps {
  modalData: ModalProps;
  selectedProjectId: string;
  closeModal: () => unknown;
}

export default function ChangeStateModal({ modalData, selectedProjectId, closeModal }: ChangeStateModalProps) {
  const handleOkClick = async () => {
    try {
      const { data } = await projectApi.updateProjectUnRelease(selectedProjectId);

      if (data.success) {
        notification.success({
          message: '미공개 상태로 변경되었습니다.'
        });
        closeModal();
      }
    } catch (e) {
      notification.error({
        message: '상태 변경에 실패했습니다.'
      });
      console.error(e);
    }
  };

  return (
    <Modal {...modalData} title="미공개 처리" transitionName="" okText="확인" cancelText="닫기" onOk={handleOkClick}>
      <div>미공개 처리하시겠습니까?</div>
    </Modal>
  );
}
