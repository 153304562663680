import { AmuletCreateOrUpdateRequestModel } from '@uniquegood/realworld-admin-interface';
import { Form, Input, Modal, ModalProps, message } from 'antd';
import { amuletApi } from '@src/apis/admin';

interface AddAmuletModalProps {
  modalData: ModalProps;
  closeModal: () => unknown;
  gradeId: string;
  refetch: () => unknown;
}

export default function AddAmuletModal({ modalData, closeModal, gradeId, refetch }: AddAmuletModalProps) {
  const [form] = Form.useForm();

  const handleSubmit = async (values: AmuletCreateOrUpdateRequestModel) => {
    try {
      const { data } = await amuletApi.createAmulet({
        ...values,
        gradeId
      });

      if (data.success) {
        await refetch();
        form.resetFields();
        closeModal();
        message.success('부적을 추가했습니다.');
      }
    } catch (e) {
      console.error(e);
      message.error('부적 추가에 실패했습니다.');
    }
  };

  return (
    <Modal {...modalData} title="부적 생성" onOk={() => form.submit()} okText="확인" cancelText="닫기">
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item label="부적 이름" name="name" rules={[{ required: true, message: '이름을 입력해주세요.' }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="부적 이미지"
          name="imageUrl"
          rules={[{ required: true, message: '이미지 URL을 입력해주세요.' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="부적 설명" name="description" rules={[{ required: true, message: '설명을 입력해주세요.' }]}>
          <Input />
        </Form.Item>
        <Form.Item label="부적 카테고리" name="category">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
