import { css } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import dayjs from 'dayjs';
import { is } from 'superstruct';
import { Divider, Tabs } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { PageHeader } from '@ant-design/pro-components';
import { Key } from 'antd/es/table/interface';
import { useAsyncFn } from 'react-use';
import DefaultLayout from '@src/components/DefaultLayout';
import { giftCodePurposeLabel } from '@src/constants/giftCodePurpose';
import { giftCodeStatusLabel } from '@src/constants/giftCodeStatus';
import { giftCodeDataColumnKeyList } from '@src/models/giftCodeDataColumnKey';
import { giftCodeDataColumnKeyLabel } from '@src/constants/giftCodeDataColumnKey';
import { GetGiftCardsProps, GetGiftCardsResponse } from '@src/apis/admin-old/getGiftCards';
import { adminApi } from '@src/apis/admin-old';
import {
  parseAdminExpiredTokenError,
  parseAdminInvalidTokenError,
  parseAdminLoginRequiredError,
  parseAdminNotFoundItemError
} from '@src/apis/admin-old/error/parseError';
import { GiftCodeDateType } from '@src/models/giftCodeDateType';
import { downloadExcel } from '@src/utils/excel';
import { getGiftCodeUrl } from '@src/utils/giftCode';
import { pageKeyToPathnameFn } from '@src/constants/page';
import { PageKey } from '@src/models/page';
import { getLocalStorage } from '@src/utils/localStorage';
import GiftCardListPageFilter, {
  GiftCardListPageFilterData,
  GiftCardListPageFilterDataStruct
} from './components/Filter';
import GiftCardListPageTable from './components/Table';
import GiftCardListPageEditModal, { GiftCardListPageEditModalOnConfirmProps } from './components/modals/Edit';
import GiftCardListPageExcelDownloadModal from './components/modals/ExcelDownload';
import GiftCardListPageProvideModal from './components/modals/Provide';

export enum GiftCardListPageTab {
  ALL = 'ALL',
  UNPROVIDED = 'UNPROVIDED',
  AFTER_PROVIDED = 'AFTER_PROVIDED'
}

const GiftCardListPageTabLabel: Record<GiftCardListPageTab, string> = {
  [GiftCardListPageTab.ALL]: '전체',
  [GiftCardListPageTab.UNPROVIDED]: '미지급',
  [GiftCardListPageTab.AFTER_PROVIDED]: '지급완료/사용완료'
};

export enum GiftCardListModalKey {
  EDIT = 'EDIT',
  PROVIDE = 'PROVIDE',
  EXCEL_DOWNLOAD = 'EXCEL_DOWNLOAD'
}

function GiftCardListPage() {
  const accessToken = getLocalStorage('accessToken');
  const navigate = useNavigate();

  // 쿼리스트링에 저장되어있는 기본값 가져오는 로직 시작
  const queryStringData = new URLSearchParams(window.location.search);
  const preFilterDefaultValue = {
    active: queryStringData.get('active') || undefined,
    date: [dayjs(queryStringData.get('startDate')), dayjs(queryStringData.get('endDate'))],
    dateType: queryStringData.get('dateType') || undefined,
    projects: queryStringData.get('projects')?.split(',') || undefined,
    purposes: queryStringData.get('purposes')?.split(',') || undefined,
    searchText: queryStringData.get('searchText') || undefined,
    searchType: queryStringData.get('searchType') || undefined,
    statuses: queryStringData.get('statuses')?.split(',') || undefined
  };
  const filterDefaultValue: GiftCardListPageFilterData = (() => {
    if (is(preFilterDefaultValue, GiftCardListPageFilterDataStruct)) {
      return preFilterDefaultValue;
    }
    const data: GiftCardListPageFilterData = {
      active: undefined,
      date: [dayjs(new Date()).add(-6, 'month'), dayjs()],
      dateType: GiftCodeDateType.CREATED_AT,
      projects: queryStringData.get('projects')?.split(',') || undefined,
      purposes: undefined,
      searchText: undefined,
      searchType: 'keyword',
      statuses: undefined
    };

    return data;
  })();
  const defaultTabValue = (queryStringData.get('tab') as GiftCardListPageTab) || GiftCardListPageTab.ALL;
  const [searchFormData, setSearchFormData] = React.useState<GiftCardListPageFilterData>(filterDefaultValue);
  const [tab, setTab] = React.useState<GiftCardListPageTab>(defaultTabValue);
  const [rowsPerPage, setRowsPerPage] = React.useState(parseInt(queryStringData.get('pageSize') || '500', 10));
  const [page, setPage] = React.useState(parseInt(queryStringData.get('page') || '1', 10));
  // 쿼리스트링에 저장되어있는 기본값 가져오는 로직 끝

  const [selected, setSelected] = React.useState<Key[]>([]);

  const [giftCardListState, fetchGiftCardList] = useAsyncFn<
    (
      searchForm: GiftCardListPageFilterData,
      page: number,
      rowsPerPage: number
    ) => Promise<GetGiftCardsResponse | undefined>
  >(
    async (searchForm: GiftCardListPageFilterData, page: number, rowsPerPage: number) => {
      if (!accessToken) {
        throw new Error('No access token');
      }

      const request: GetGiftCardsProps = {
        accessToken,
        searchDateType: searchForm.dateType,
        searchIsActivate: searchForm.active,
        searchPurposes: searchForm.purposes,
        searchStartDate: searchForm.date[0].toDate(),
        searchEndDate: searchForm.date[1].toDate(),
        searchStatuses: searchForm.statuses,
        projectIds: searchForm.projects,
        page,
        take: rowsPerPage
      };

      if (searchForm.searchText) {
        switch (searchForm.searchType) {
          case 'keyword':
            request.searchKeyword = searchForm.searchText;
            break;
          case 'giftCode':
            request.searchGiftCodes = searchForm.searchText;
            break;
          case 'accountId':
            request.searchAccountId = searchForm.searchText;
            break;
          default:
            console.error('검색 타입이 없음');
        }
      }

      const response = await adminApi.getGiftCards(request);

      return response;
    },
    [accessToken, navigate]
  );

  React.useEffect(() => {
    if (giftCardListState.error) {
      if (
        parseAdminLoginRequiredError(giftCardListState.error)[1] ||
        parseAdminExpiredTokenError(giftCardListState.error)[1] ||
        parseAdminInvalidTokenError(giftCardListState.error)[1]
      ) {
        navigate(pageKeyToPathnameFn[PageKey.LOGIN]());
      }
      if (parseAdminNotFoundItemError(giftCardListState.error)[1]) {
        navigate(pageKeyToPathnameFn[PageKey.GIFT_CARD_CONTENT_LIST]());
      }
      console.error(giftCardListState.error);
    }
  }, [giftCardListState.error, navigate]);

  // GET 기프트카드 목록 불러오는 API 호출부 시작
  React.useEffect(() => {
    fetchGiftCardList(filterDefaultValue, page, rowsPerPage);
  }, [fetchGiftCardList]);

  const handleChangePage = React.useCallback(
    (nextValue: number) => {
      setPage(nextValue);
      navigate(
        pageKeyToPathnameFn[PageKey.GIFT_CARD_LIST]({
          querystring: {
            active: searchFormData.active,
            startDate: searchFormData.date[0].format(),
            endDate: searchFormData.date[1].format(),
            dateType: searchFormData.dateType,
            projects: searchFormData.projects?.join(','),
            purposes: searchFormData.purposes?.join(','),
            searchText: searchFormData.searchText,
            searchType: searchFormData.searchType,
            statuses: searchFormData.statuses?.join(','),
            page: nextValue,
            pageSize: rowsPerPage,
            tab
          }
        }),
        { replace: true }
      );
      fetchGiftCardList(searchFormData, nextValue, rowsPerPage);
    },
    [fetchGiftCardList, navigate, rowsPerPage, searchFormData, tab]
  );
  const handleChangeRowsPerPage = React.useCallback(
    (nextValue: number) => {
      setRowsPerPage(nextValue);
      setPage(1);
      navigate(
        pageKeyToPathnameFn[PageKey.GIFT_CARD_LIST]({
          querystring: {
            active: searchFormData.active,
            startDate: searchFormData.date[0].format(),
            endDate: searchFormData.date[1].format(),
            dateType: searchFormData.dateType,
            projects: searchFormData.projects?.join(','),
            purposes: searchFormData.purposes?.join(','),
            searchText: searchFormData.searchText,
            searchType: searchFormData.searchType,
            statuses: searchFormData.statuses?.join(','),
            page: 1,
            pageSize: nextValue,
            tab
          }
        }),
        { replace: true }
      );
      fetchGiftCardList(searchFormData, 1, nextValue);
    },
    [fetchGiftCardList, navigate, searchFormData, tab]
  );
  const handleSubmitFilter = React.useCallback(
    (data: GiftCardListPageFilterData) => {
      setSearchFormData(data);
      navigate(
        pageKeyToPathnameFn[PageKey.GIFT_CARD_LIST]({
          querystring: {
            active: data.active,
            startDate: data.date[0].format(),
            endDate: data.date[1].format(),
            dateType: data.dateType,
            projects: data.projects?.join(','),
            purposes: data.purposes?.join(','),
            searchText: data.searchText,
            searchType: data.searchType,
            statuses: data.statuses?.join(','),
            page,
            pageSize: rowsPerPage,
            tab
          }
        }),
        { replace: true }
      );
      fetchGiftCardList(data, page, rowsPerPage);
    },
    [fetchGiftCardList, navigate, page, rowsPerPage, tab]
  );
  // GET 기프트카드 목록 불러오는 API 호출부 끝
  const handleChangeTab = React.useCallback((newValue: string) => {
    setTab(newValue as GiftCardListPageTab);
  }, []);

  const [openModalKey, setOpenModalKey] = React.useState<GiftCardListModalKey | undefined>(undefined);
  const handleCloseModal = React.useCallback(() => {
    setOpenModalKey(undefined);
  }, []);
  const handleOpenEditModal = React.useCallback(() => {
    setOpenModalKey(GiftCardListModalKey.EDIT);
  }, []);
  const handleOpenProvideModal = React.useCallback(() => {
    setOpenModalKey(GiftCardListModalKey.PROVIDE);
  }, []);
  const handleOpenExcelDownloadModal = React.useCallback(() => {
    setOpenModalKey(GiftCardListModalKey.EXCEL_DOWNLOAD);
  }, []);

  const [provideGiftCodesState, doFetchProvideGiftCodes] = useAsyncFn(async () => {
    if (!accessToken) {
      navigate(pageKeyToPathnameFn[PageKey.LOGIN]());
      return;
    }

    await adminApi.patchGiftCardsStatus({
      accessToken,
      giftCodes: selected.map((v) => v as string)
    });
    React.startTransition(() => setOpenModalKey(undefined));
    fetchGiftCardList(searchFormData, page, rowsPerPage);
  }, [accessToken, navigate, selected]);

  React.useEffect(() => {
    if (!provideGiftCodesState.error) return;
    if (
      parseAdminLoginRequiredError(provideGiftCodesState.error)[1] ||
      parseAdminExpiredTokenError(provideGiftCodesState.error)[1] ||
      parseAdminInvalidTokenError(provideGiftCodesState.error)[1]
    ) {
      navigate(pageKeyToPathnameFn[PageKey.LOGIN]());
    }
    if (parseAdminNotFoundItemError(provideGiftCodesState.error)[1]) {
      navigate(pageKeyToPathnameFn[PageKey.GIFT_CARD_CONTENT_LIST]());
    }
    console.error(provideGiftCodesState.error);
  }, [navigate, provideGiftCodesState.error]);

  const [editGiftCodesState, doFetchEditGiftCodes] = useAsyncFn(
    async (confirmValue: GiftCardListPageEditModalOnConfirmProps) => {
      if (!accessToken) {
        navigate(pageKeyToPathnameFn[PageKey.LOGIN]());
        return;
      }

      await adminApi.patchGiftCards({
        accessToken,
        giftCodes: selected.map((v) => v as string),
        keyword: confirmValue.keyword,
        price: confirmValue.price,
        isActivate: confirmValue.active
      });
      React.startTransition(() => setOpenModalKey(undefined));
      fetchGiftCardList(searchFormData, page, rowsPerPage);
    },
    [accessToken, fetchGiftCardList, navigate, page, rowsPerPage, searchFormData, selected]
  );

  React.useEffect(() => {
    if (!editGiftCodesState.error) return;
    if (
      parseAdminLoginRequiredError(editGiftCodesState.error)[1] ||
      parseAdminExpiredTokenError(editGiftCodesState.error)[1] ||
      parseAdminInvalidTokenError(editGiftCodesState.error)[1]
    ) {
      navigate(pageKeyToPathnameFn[PageKey.LOGIN]());
    }
    if (parseAdminNotFoundItemError(editGiftCodesState.error)[1]) {
      navigate(pageKeyToPathnameFn[PageKey.GIFT_CARD_CONTENT_LIST]());
    }
    console.error(editGiftCodesState.error);
  }, [editGiftCodesState.error, navigate]);

  const handleClickExcelDownloadGiftCodes = React.useCallback(() => {
    if (!accessToken) {
      navigate(pageKeyToPathnameFn[PageKey.LOGIN]());
      return;
    }

    if (!giftCardListState.value) return;

    try {
      downloadExcel({
        keys: [...giftCodeDataColumnKeyList, 'giftCodeUrl'],
        header: { ...giftCodeDataColumnKeyLabel, giftCodeUrl: '기프트카드 URL' },
        data: giftCardListState.value.data
          .filter(({ giftCode }) => selected.includes(giftCode))
          .map((row) => ({
            ...row,
            createdAt: dayjs(row.createdAt).format('YYYY-MM-DD'),
            issuedAt: row.issuedAt ? dayjs(row.issuedAt).format('YYYY-MM-DD HH:mm:ss') : '',
            usedAt: row.usedAt ? dayjs(row.usedAt).format('YYYY-MM-DD HH:mm:ss') : '',
            purpose: giftCodePurposeLabel[row.purpose],
            status: giftCodeStatusLabel[row.status],
            isActivate: row.isActivate ? '활성' : '비활성',
            price: String(row.price),
            accountId: row.accountId ? row.accountId : '',
            giftCodeUrl: getGiftCodeUrl(row.giftCode)
          })),
        fileName: `기프트카드조회-${dayjs().format()}`,
        type: 'csv'
      });
    } catch (error) {
      console.error(error);
    }
  }, [accessToken, giftCardListState.value, navigate, selected]);

  return (
    <DefaultLayout>
      <PageHeader
        title="기프트카드 조회 페이지"
        subTitle="기프트카드를 조회합니다."
        style={{ margin: '16px 20px 0px 20px' }}
      />
      <Divider />
      <Content style={{ padding: '0px 24px', margin: '0px 20px' }}>
        <Tabs
          type="card"
          activeKey={tab}
          items={Object.values(GiftCardListPageTab).map((key) => ({
            label: GiftCardListPageTabLabel[key],
            key
          }))}
          onTabClick={handleChangeTab}
        />
        {accessToken && (
          <GiftCardListPageFilter
            accessToken={accessToken}
            tab={tab}
            defaultValue={filterDefaultValue}
            onSubmit={handleSubmitFilter}
            css={filterStyle}
            onChangeTab={handleChangeTab}
          />
        )}
        <GiftCardListPageTable
          isLoading={giftCardListState.loading}
          selected={selected}
          handleChangeSelected={setSelected}
          page={page}
          pageSize={rowsPerPage}
          handleOpenEditModal={handleOpenEditModal}
          handleOpenProvideModal={handleOpenProvideModal}
          handleOpenExcelDownloadModal={handleOpenExcelDownloadModal}
          handleChangePage={handleChangePage}
          handleChangePageSize={handleChangeRowsPerPage}
          data={giftCardListState.value?.data}
          totalCount={giftCardListState.value?.count}
          tab={tab}
        />
      </Content>

      <GiftCardListPageEditModal
        isOpen={openModalKey === GiftCardListModalKey.EDIT}
        onCloseModal={handleCloseModal}
        selectedCount={selected.length}
        onConfirm={doFetchEditGiftCodes}
        isLoading={editGiftCodesState.loading}
      />
      <GiftCardListPageProvideModal
        isOpen={openModalKey === GiftCardListModalKey.PROVIDE}
        onCloseModal={handleCloseModal}
        selectedCount={selected.length}
        onConfirm={doFetchProvideGiftCodes}
        isLoading={provideGiftCodesState.loading}
      />
      <GiftCardListPageExcelDownloadModal
        isOpen={openModalKey === GiftCardListModalKey.EXCEL_DOWNLOAD}
        onCloseModal={handleCloseModal}
        selectedCount={selected.length}
        onConfirm={handleClickExcelDownloadGiftCodes}
      />
    </DefaultLayout>
  );
}

const filterStyle = css`
  margin-bottom: 15px;
`;

export default GiftCardListPage;
