import { Navigate, Outlet } from 'react-router-dom';
import { useAuthCheck } from '@src/hooks/useAuthCheck';

export default function ProtectedRouter() {
  const { isLoading, isLoggedIn } = useAuthCheck();

  if (isLoading) {
    return null;
  }

  if (!isLoggedIn) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
}
