import { Badge, Button, Divider, Space, Table, Tooltip, message } from 'antd';
import { useAsyncFn } from 'react-use';
import { DndContext } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { PageHeader } from '@ant-design/pro-components';
import { Content } from 'antd/es/layout/layout';
import React from 'react';
import { NoticePopup } from '@uniquegood/realworld-admin-interface/dist';
import { ColumnsType } from 'antd/es/table';
import { MenuOutlined } from '@ant-design/icons';
import { noticeApi } from '@src/apis/admin';
import DefaultLayout from '@src/components/DefaultLayout';
import useModalState from '@src/hooks/useModalState';
import { uuidv4 } from '@src/utils/uuid';
import { PopupCreateModal } from './PopupCreateModal';
import { PopupDeleteCheckModal } from './PopupDeleteCheckModal';
import { PopupModifyModal } from './PopupModifyModal';
import type { DragEndEvent, UniqueIdentifier } from '@dnd-kit/core';

export type Popup = NoticePopup & { key: React.Key };

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

function Row({ children, ...props }: RowProps) {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({
    id: props['data-row-key']
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 })?.replace(
      /translate3d\(([^,]+),/,
      'translate3d(0,'
    ),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {})
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === 'sort') {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined ref={setActivatorNodeRef} style={{ touchAction: 'none', cursor: 'move' }} {...listeners} />
            )
          });
        }
        return child;
      })}
    </tr>
  );
}

export default function PopUps() {
  const [currentPopupList, setCurrentPopupList] = React.useState<Popup[]>([]);
  const [selectedPopup, setSelectedPopup] = React.useState<Popup>({
    key: '',
    link: '',
    image: '',
    message: '',
    isVisible: false
  });

  const [popUpDataApi, fetchPopUpDataApi] = useAsyncFn(async () => {
    const { data } = await noticeApi.getNoticePopupsSetting();

    const modifiedData = data.popups.map((popup) => ({
      ...popup,
      key: uuidv4()
    }));

    setCurrentPopupList(modifiedData);

    return modifiedData;
  }, []);

  const {
    openModal: openPopupCreateModal,
    closeModal: closePopupCreateModal,
    modal: popupCreateModal
  } = useModalState();
  const {
    openModal: openPopupModifyModal,
    closeModal: closePopupModifyModal,
    modal: popupModifyModal
  } = useModalState();
  const {
    openModal: openPopupDeleteCheckModal,
    closeModal: closePopupDeleteCheckModal,
    modal: popupDeleteCheckModal
  } = useModalState();

  const handleAddClick = () => {
    openPopupCreateModal({
      title: '팝업 추가',
      transitionName: ''
    });
  };

  const handleModifyClick = () => {
    openPopupModifyModal({
      title: '팝업 수정',
      transitionName: ''
    });
  };

  const handleDeleteClick = () => {
    openPopupDeleteCheckModal({
      title: '팝업 삭제',
      transitionName: ''
    });
  };

  const handleDragEnd = async ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const previousPopupList = currentPopupList;
      let nextPopupList: Popup[] | null = null;
      setCurrentPopupList((previousPopupList) => {
        const activeIndex = previousPopupList.findIndex((i) => i.key === active.id);
        const overIndex = previousPopupList.findIndex((i) => i.key === over?.id);

        nextPopupList = arrayMove(previousPopupList, activeIndex, overIndex);

        return nextPopupList;
      });

      if (!nextPopupList) return;

      const { data } = await noticeApi.editNoticePopupsSetting({
        popups: nextPopupList
      });

      if (data) {
        message.success('팝업 순서가 변경되었습니다.');
      } else {
        setCurrentPopupList(previousPopupList);
        message.error('오류가 발생해 순서를 변경하지 못했습니다.');
      }
    }
  };

  const columns: ColumnsType<Popup> = [
    {
      key: 'sort',
      width: '50px'
    },
    {
      key: 'id',
      dataIndex: 'id',
      title: 'ID',
      width: 200
    },
    {
      key: 'message',
      dataIndex: 'message',
      title: '팝업 타이틀'
    },
    {
      key: 'isVisible',
      dataIndex: 'isVisible',
      title: '팝업 표시 여부',
      width: '120px',
      render: (value: boolean) =>
        value ? <Badge status="success" text="표시" /> : <Badge status="error" text="미표시" />
    },
    {
      key: 'link',
      dataIndex: 'link',
      title: '클릭 시 이동할 URL'
    },
    {
      key: 'image',
      dataIndex: 'image',
      title: '팝업 이미지',
      render: (value) => {
        return (
          <Tooltip placement="top" title={value}>
            <a
              href={value}
              target="blank"
              style={{
                display: 'inline-block',
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
            >
              {value}
            </a>
          </Tooltip>
        );
      }
    },

    {
      key: 'action',
      title: '동작',
      ellipsis: true,
      width: '100px',
      render: () => {
        return (
          <div style={{ overflowWrap: 'normal' }}>
            <Button onClick={handleModifyClick}>수정</Button>
            <br />
            <Button danger onClick={handleDeleteClick}>
              삭제
            </Button>
          </div>
        );
      }
    }
  ];

  React.useEffect(() => {
    fetchPopUpDataApi();
  }, [fetchPopUpDataApi]);

  return (
    <>
      <DefaultLayout>
        <PageHeader
          title="팝업 페이지"
          subTitle="앱 구동시 표시되는 팝업을 설정합니다."
          style={{ margin: '16px 20px 0px 20px' }}
        />
        <Divider />
        <Content style={{ padding: '0px 24px', margin: '0px 20px', marginBottom: '48px' }}>
          <Space style={{ width: '100%', marginBottom: '16px' }}>
            <Button onClick={handleAddClick}>팝업 추가하기</Button>
          </Space>
          <DndContext onDragEnd={handleDragEnd}>
            <SortableContext
              // @types/react 의 React.Key 타입에 bigint 타입이 추가되어 임시로 타입 단언 사용했습니다.
              // SortableContext의 타입이 변경되면 타입 단언 제거해주세요.
              items={currentPopupList.map((item) => item.key as UniqueIdentifier)}
              strategy={verticalListSortingStrategy}
            >
              <Table
                components={{
                  body: {
                    row: Row
                  }
                }}
                dataSource={currentPopupList}
                columns={columns}
                rowKey="key"
                loading={popUpDataApi.loading}
                pagination={false}
                onRow={(record) => {
                  return {
                    onClick: () => {
                      setSelectedPopup(record);
                    }
                  };
                }}
              />
            </SortableContext>
          </DndContext>
        </Content>
      </DefaultLayout>
      <PopupCreateModal
        modal={popupCreateModal}
        closeModal={closePopupCreateModal}
        currentValues={currentPopupList}
        refetchFn={fetchPopUpDataApi}
      />
      <PopupModifyModal
        modal={popupModifyModal}
        closeModal={closePopupModifyModal}
        currentValues={currentPopupList}
        selectedPopup={selectedPopup}
        refetchFn={fetchPopUpDataApi}
      />
      <PopupDeleteCheckModal
        modal={popupDeleteCheckModal}
        closeModal={closePopupDeleteCheckModal}
        selectedPopup={selectedPopup}
        currentValues={currentPopupList}
        refetchFn={fetchPopUpDataApi}
      />
    </>
  );
}
