import { PageHeader } from '@ant-design/pro-components';
import { Divider, Form, Input, Table, Tag, Tooltip } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { ColumnsType } from 'antd/es/table';
import { useAsync } from 'react-use';
import React from 'react';
import { ApplicationsResponseModel, ChannelType } from '@uniquegood/realworld-admin-interface';
import dayjs from 'dayjs';
import { Link, useLocation } from 'react-router-dom';
import DefaultLayout from '@src/components/DefaultLayout';
import { applicationApi } from '@src/apis/admin';
import { NarrowChannelType, channelTypeToLabel } from '@src/constants/channel';
import { STUDIO_HOST } from '@src/config';

const { Search } = Input;

export default function Channel() {
  const location = useLocation();

  const [searchKeyword, setSearchKeyword] = React.useState(new URLSearchParams(location.search).get('search') || '');
  const [currentPage, setCurrentPage] = React.useState(1);

  const channelList = useAsync(async () => {
    const { data } = await applicationApi.getApplications(searchKeyword, currentPage, 10);

    return data.data;
  }, [searchKeyword, currentPage]);

  const columns: ColumnsType<ApplicationsResponseModel> = [
    {
      key: 'id',
      dataIndex: 'id',
      title: 'ID',
      render: (value) => {
        return <Link to={`/creator/channel/${value}`}>{value || ''}</Link>;
      }
    },
    {
      key: 'name',
      dataIndex: 'name',
      title: '이름'
    },
    {
      key: 'channelType',
      dataIndex: 'channelType',
      title: '권한',
      render: (value: NarrowChannelType) => {
        return <Tag color={value === ChannelType.Normal ? '#777' : '#c869ff'}>{channelTypeToLabel[value]}</Tag>;
      }
    },
    {
      key: 'ownerName',
      dataIndex: 'ownerName',
      title: '소유자',
      render: (value) => (
        <Tooltip title={value.join(', ')}>
          <div
            style={{
              maxWidth: '200px',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              wordBreak: 'break-all'
            }}
          >
            {value.join(', ')}
          </div>
        </Tooltip>
      )
    },
    {
      key: 'projectCount',
      dataIndex: 'projectCount',
      title: '게임 수',
      render: (value, record) => {
        return <Link to={`/creator/channel/${record.id}/project`}>{value || 0}</Link>;
      }
    },
    {
      key: 'createdAt',
      dataIndex: 'createdAt',
      title: '생성일',
      render: (value) => {
        return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
      }
    },
    {
      title: '뷰어',
      render: (value) => {
        return (
          <a href={`https://realworld-viewer.azurewebsites.net/apps/${value.id}`} target="blank">
            뷰어 열기
          </a>
        );
      }
    },
    {
      title: '스튜디오',
      render: (_, record) => {
        return (
          <a href={`https://${STUDIO_HOST}/apps/${record.id}?tab=games`} target="blank">
            스튜디오 열기
          </a>
        );
      }
    }
  ];

  React.useEffect(() => {
    const handleSearchKeyword = () => {
      const keyword = new URLSearchParams(window.location.search).get('search') || '';

      setSearchKeyword(keyword);
    };

    window.addEventListener('popstate', handleSearchKeyword);

    return () => {
      window.removeEventListener('popstate', handleSearchKeyword);
    };
  }, []);

  return (
    <DefaultLayout>
      <PageHeader title="채널 관리" subTitle="생성된 채널을 관리합니다." style={{ margin: '16px 20px 0px 20px' }} />
      <Divider />
      <Content style={{ padding: '0 24px', margin: '0px 20px' }}>
        <Form
          fields={[
            {
              name: 'search',
              value: searchKeyword
            }
          ]}
        >
          <Form.Item name="search">
            <Search
              onSearch={(value) => {
                setSearchKeyword(value);
                setCurrentPage(1);

                window.history.pushState('', '', `?search=${value}`);
              }}
              placeholder="검색"
              enterButton
              style={{ marginBottom: '16px', width: '40%' }}
            />
          </Form.Item>
        </Form>
        <Table
          dataSource={channelList.value?.data || []}
          columns={columns}
          loading={channelList.loading}
          pagination={{
            showSizeChanger: false,
            total: channelList.value?.count,
            onChange: (page) => {
              setCurrentPage(page);
            }
          }}
          scroll={{
            x: 1400
          }}
        />
      </Content>
    </DefaultLayout>
  );
}
