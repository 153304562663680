import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';

export default function Layout({ children }: PropsWithChildren) {
  return (
    <Container>
      <LogoWrapper>
        <img src="/new_logo.png" alt="리얼월드 로고" />
      </LogoWrapper>
      <Description>리얼월드 어드민에 오신 것을 환영합니다</Description>
      {children}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

const LogoWrapper = styled.div`
  position: relative;
  width: 200px;
  height: 32px;
  margin-bottom: 16px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const Description = styled.p`
  margin-bottom: 24px;
  color: #797d88;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.1px;
`;
