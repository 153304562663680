import { Modal, ModalProps, message } from 'antd';
import { noticeApi } from '@src/apis/admin';
import { Popup } from '.';

interface PopupDeleteCheckModalProps {
  modal: ModalProps;
  closeModal: () => unknown;
  selectedPopup: Popup;
  currentValues: Popup[];
  refetchFn: () => unknown;
}

export function PopupDeleteCheckModal({
  modal,
  closeModal,
  currentValues,
  selectedPopup,
  refetchFn
}: PopupDeleteCheckModalProps) {
  const handleOkClick = async () => {
    const newValues = currentValues.filter((popup) => popup.key !== selectedPopup.key);

    try {
      await noticeApi.editNoticePopupsSetting({
        popups: newValues
      });
      await refetchFn();
      closeModal();
      message.success('삭제 성공했습니다.');
    } catch (e) {
      console.error(e);
      message.error('오류가 발생해 삭제에 실패했습니다.');
    }
  };

  return (
    <Modal {...modal} okText="확인" cancelText="취소" onOk={handleOkClick}>
      <div>정말 삭제하시겠어요?</div>
    </Modal>
  );
}
