import axios from 'axios';
import { CORE_SERVER_HOST } from '@src/config';

export const authApi = axios.create({
  baseURL: `https://${CORE_SERVER_HOST}`,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const postLogin = async ({ id, password }: { id: string; password: string }) => {
  return authApi.post('/api/auth/login', {
    id,
    password,
    provider: 'Self'
  });
};

export const postLogout = async () => {
  return authApi.post('/api/auth/logout', undefined, {
    withCredentials: true
  });
};

export const postRefreshToken = async ({
  accessToken,
  refreshToken
}: {
  accessToken: string;
  refreshToken: string;
}) => {
  return authApi.post<{ token: string; refreshToken: string; expiresAt: string }>('/api/auth/refresh', {
    accessToken,
    refreshToken
  });
};
