import { PageHeader } from '@ant-design/pro-components';
import { Button, Divider } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useAsyncFn } from 'react-use';
import React from 'react';
import DefaultLayout from '@src/components/DefaultLayout';
import { amuletApi } from '@src/apis/admin';
import useModalState from '@src/hooks/useModalState';
import ToggleList from './ToggleList';
import AddGradeModal from './AddGradeModal';

export default function Amulet() {
  const { openModal: openAddModal, closeModal: closeAddModal, modal: addModal } = useModalState();

  const [amuletGradeList, fetchAmuletGradeList] = useAsyncFn(async () => {
    const { data } = await amuletApi.getAmuletGradeList();

    return data;
  }, []);

  const [amuletList, fetchAmuletList] = useAsyncFn(async () => {
    const { data } = await amuletApi.getAmuletList();

    return data;
  }, []);

  React.useEffect(() => {
    fetchAmuletList();
    fetchAmuletGradeList();
  }, [fetchAmuletList, fetchAmuletGradeList]);

  return (
    <>
      <DefaultLayout>
        <PageHeader title="부적 관리" subTitle="다구 부적을 관리합니다." style={{ margin: '16px 20px 0px 20px' }} />
        <Divider />
        <Content style={{ padding: '0px 24px', margin: '0px 20px' }}>
          <div style={{ textAlign: 'right' }}>
            <Button type="primary" onClick={() => openAddModal({})}>
              부적 등급 추가
            </Button>
          </div>
          {amuletList.value?.data.map((amulet) => (
            <ToggleList
              key={amulet.gradeId}
              gradeId={amulet.gradeId}
              title={amulet.gradeName}
              gradeList={amuletGradeList.value?.data || []}
              weight={amulet.weight}
              totalWeight={amuletGradeList.value?.data.reduce((acc, cur) => acc + cur.weight, 0) || 0}
              imageUrl={amulet.imageUrl}
              iconImageUrl={amulet.iconImageUrl}
              amulets={amulet.amulets || []}
              refetch={fetchAmuletList}
              refetchGrade={fetchAmuletGradeList}
            />
          ))}
        </Content>
      </DefaultLayout>
      <AddGradeModal
        modalData={addModal}
        closeModal={closeAddModal}
        refetch={fetchAmuletList}
        refetchGrade={fetchAmuletGradeList}
        gradeList={amuletGradeList.value?.data || []}
      />
    </>
  );
}
