import { ProjectLite, RecommendedKeywordResponseModel } from '@uniquegood/realworld-admin-interface';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import { Button, Tooltip, message } from 'antd';
import React from 'react';
import { css } from '@emotion/react';
import { useAsync } from 'react-use';
import { uuidv4 } from '@src/utils/uuid';
import useModalState from '@src/hooks/useModalState';
import { projectApi } from '@src/apis/admin';
import CreateModal from './CreateModal';
import KeywordItem from './KeywordItem';

type RecommendedKeywordListProps = {
  recommendedKeywords: RecommendedKeywordResponseModel[];
  onChange: (values: (RecommendedKeywordResponseModel & { id: string })[]) => unknown;
};

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? '#e6f4ff' : 'none',
  // display: 'flex',
  padding: '16px 0'
});

export default function RecommendedKeywordList({ recommendedKeywords, onChange }: RecommendedKeywordListProps) {
  const { openModal: openCreateModal, closeModal: closeCreateModal, modal: createModal } = useModalState();

  const totalProjects = useAsync(async () => {
    const { data: preResponse } = await projectApi.getProjects(undefined, undefined, undefined, 1, 1);

    const { data } = await projectApi.getProjects(undefined, undefined, undefined, 1, preResponse.data.count);

    return data.data.data;
  }, []);

  const recommendedKeywordsWithId = React.useMemo(
    () =>
      recommendedKeywords.map((recommendedKeyword) => ({
        ...recommendedKeyword,
        id: uuidv4()
      })),
    []
  );

  const [keywords, setKeywords] = React.useState(recommendedKeywordsWithId);

  const handleDragEnd: OnDragEndResponder = async (result) => {
    try {
      if (!result.destination) return;

      const sourceIndex = result.source.index;
      const destinationIndex = result.destination.index;

      if (sourceIndex === destinationIndex) return;

      const source = keywords.find((keyword) => keyword.id === result.draggableId)!;
      const newKeywords = keywords;
      newKeywords.splice(sourceIndex, 1);
      newKeywords.splice(destinationIndex, 0, source);

      setKeywords(newKeywords);
      onChange(newKeywords);

      message.success('슬라이더 순서를 변경했습니다.');
    } catch (e) {
      console.error(e);
      message.error('슬라이더 순서 변경에 실패했습니다.');
    }
  };

  const handleCreateClick = () => {
    openCreateModal({});
  };

  const handleCreateBannerSubmit = (values: RecommendedKeywordResponseModel) => {
    setKeywords((prev) => {
      const newKeyword = {
        ...values,
        id: uuidv4(),
        order: prev.length
      };

      return [...prev, newKeyword];
    });

    closeCreateModal();
  };

  const handleCloseKeywordClick = (id: string) => {
    setKeywords((prev) => prev.filter((keyword) => keyword.id !== id));
  };

  const handleProjectChange = (id: string, projects: ProjectLite[]) => {
    setKeywords((prev) => {
      const newKeywords = prev.map((keyword) => {
        if (keyword.id === id) {
          return {
            ...keyword,
            projects
          };
        }
        return keyword;
      });

      return newKeywords;
    });
  };

  React.useEffect(() => {
    onChange(keywords);
  }, [keywords]);

  return (
    <>
      <div>
        <Tooltip
          placement="right"
          title="추천 키워드가 없습니다. 추가하기를 눌러 추천 키워드를 추가해주세요."
          zIndex={1}
          open={keywords.length === 0}
        >
          <Button onClick={handleCreateClick}>추가하기</Button>
        </Tooltip>
      </div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <div css={containerStyle}>
          <Droppable droppableId="subSlider" direction="vertical">
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}>
                {keywords?.map((keyword, index) => {
                  return (
                    <Draggable key={keyword.id} draggableId={keyword.id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{ ...provided.draggableProps.style, marginBottom: '4px' }}
                        >
                          <KeywordItem
                            id={keyword.id}
                            keyword={keyword.keyword}
                            initialProjects={keyword.projects}
                            totalProjects={totalProjects.value || []}
                            order={keyword.order}
                            onCloseClick={handleCloseKeywordClick}
                            onProjectChange={handleProjectChange}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
      <CreateModal
        modalData={createModal}
        totalProjects={totalProjects.value || []}
        onSubmit={handleCreateBannerSubmit}
      />
    </>
  );
}

const containerStyle = css`
  width: 100%;
`;
