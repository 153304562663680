import { Divider, Layout, notification, Table, Tabs } from 'antd';
import { CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { PageHeader } from '@ant-design/pro-components';
import { useSearchParams } from 'react-router-dom';
import { ADMIN_SERVER_HOST } from '@src/config';
import DefaultLayout from '@src/components/DefaultLayout';
import Filter from '@src/components/CreatorCommunity/Filter';
import { communityReportReason } from '@src/models/communityReportReason';
import { communityReportReasonLabel } from '@src/constants/communityReason';
import { communityReportStateLabel } from '@src/constants/communityState';
import { communityReportState } from '@src/models/communityReportState';
import ReportDetailModal from '@src/components/CreatorCommunity/ReportDetailModal';
import { YYYY_MM_DD } from '@src/constants/date';
import { ContentRequestType } from '@src/models/communityContentRequestType';
import { columns } from '@src/constants/communityTable';
import { getLocalStorage } from '@src/utils/localStorage';

const { Content } = Layout;

interface ReportDataType {
  key: React.Key;
  contentType: string;

  reportId: number;

  createdAtLabel: string;
  reasonLabel: string;
  author: string;
  game: string;
  description: string;
  content: string;
  state: string;
  isDeletedLabel: string;
}

interface ReportDetailDataType {
  reportId: number;
  reason: string;

  description: string;
  content: string;
  contentType: string;
  postType: string;
  state: string;
  createdAt: string;
  reportedUserId: string;
  reportedUserName: string;
  reportedContentCreatedAt: string;
  images: ReportDetailImageType[];
  isDeleted: boolean;
}

interface ReportDetailImageType {
  id: string;
  url: string;
}

const defaultPaginationSet = {
  pageNumber: 1,
  reportPerPage: 50
};

function CreatorFeedReportListPage() {
  const [contentRequestType, setContentRequestType] = useState<ContentRequestType>('post');
  const [pageNumber, setPageNumber] = useState(defaultPaginationSet.pageNumber);
  const [reportPerPage, setReportPerPage] = useState(defaultPaginationSet.reportPerPage);
  const [totalReports, setTotalReports] = useState();
  const [reportList, setReportList] = useState<ReportDataType[]>([]);
  const [reportDetail, setReportDetail] = useState<ReportDetailDataType>();
  const [selectedReportForModal, setSelectedReportForModal] = useState<
    | {
        contentType: string;
        reportId: number;
      }
    | undefined
  >();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    getReportList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, contentRequestType]);

  function handleChangeContentRequestType(contentRequestType: string) {
    (function resetSearchParams() {
      setSearchParams(new URLSearchParams());
    })();

    setContentRequestType(contentRequestType as ContentRequestType);
  }

  async function getReportList() {
    try {
      const parsedParams = Object.fromEntries(searchParams);

      if (!(parsedParams.searchStartDate && parsedParams.searchEndDate)) return;

      const res = await axios.get(
        `https://${ADMIN_SERVER_HOST}/manage/api/CreatorCommunity/${contentRequestType}/report`,
        {
          params: {
            PostType: 'feed',
            searchDateType: 'CreatedAt',
            page: pageNumber,
            take: reportPerPage,
            ...parsedParams,
            searchStartDate: `${parsedParams.searchStartDate}T00:00:00+09:00`,
            searchEndDate: `${parsedParams.searchEndDate}T23:59:59+09:00`
          },
          headers: {
            Authorization: `Bearer ${getLocalStorage('accessToken')}`
          }
        }
      );
      setTotalReports(res.data.count);
      setReportList(
        res.data.data.map((i: any) => ({
          ...i,
          createdAtLabel: dayjs(i.createdAt).format(YYYY_MM_DD),
          isDeletedLabel: i.isDeleted ? 'O' : 'X',
          reasonLabel: communityReportReasonLabel[i.reason as communityReportReason],
          stateLabel: communityReportStateLabel[i.state as communityReportState]
        }))
      );
    } catch (err) {
      console.error(err);
    }
  }

  async function getReportDetail(contentType: string, reportId: number) {
    try {
      const res = await axios.get(
        `https://${ADMIN_SERVER_HOST}/manage/api/CreatorCommunity/${contentType}/report/${reportId}`,
        {
          headers: {
            Authorization: `Bearer ${getLocalStorage('accessToken')}`
          }
        }
      );
      setReportDetail(res.data);
    } catch (err) {
      console.error(err);
    }
  }

  async function patchReportProcess(contentType: string, reportId: number, process: string) {
    try {
      await axios.patch(
        `https://${ADMIN_SERVER_HOST}/manage/api/CreatorCommunity/${contentType}/report/${reportId}`,
        {
          handlingType: process
        },
        {
          headers: {
            Authorization: `Bearer ${getLocalStorage('accessToken')}`
          }
        }
      );
      handleOnProcessed(process);
    } catch (err) {
      console.error(err);
      openFailNotification();
    }
  }

  function handleModalClose() {
    setSelectedReportForModal(undefined);
  }

  function handleReportDetailModal(contentType: string, reportId: number) {
    getReportDetail(contentType, reportId);
    setSelectedReportForModal({ contentType, reportId });
  }

  function handleReportProcess(process: string) {
    if (!selectedReportForModal) return;

    patchReportProcess(selectedReportForModal.contentType, selectedReportForModal.reportId, process);
    setSelectedReportForModal(undefined);
  }

  const openSuccessNotification = (process: string) => {
    if (process === 'deleted') {
      notification.open({
        message: '삭제 처리 완료',
        description: '신고 내용이 삭제 처리되었습니다.',
        icon: <ExclamationCircleOutlined style={{ color: '#ff4d4f' }} />,
        placement: 'bottomRight'
      });
    }

    if (process === 'rejected') {
      notification.open({
        message: '반려 처리 완료',
        description: '신고 내용이 반려 처리되었습니다.',
        icon: <ExclamationCircleOutlined style={{ color: '#faad14' }} />,
        placement: 'bottomRight'
      });
    }
  };

  const openFailNotification = () => {
    notification.open({
      message: '신고 처리 실패',
      description: '이미 처리되었거나 삭제된 신고 내용입니다.',
      icon: <CloseCircleOutlined style={{ color: '#ff4d4f' }} />,
      placement: 'bottomRight'
    });
  };

  function handleOnProcessed(process: string) {
    openSuccessNotification(process);
    getReportList();
  }

  const tabItems: { label: string; key: ContentRequestType; children: JSX.Element }[] = [
    {
      label: '게시글',
      key: 'post',
      children: (
        <>
          <Filter />
          <Table<ReportDataType>
            dataSource={reportList}
            columns={columns}
            rowKey={(record) => record.reportId}
            pagination={{
              pageSizeOptions: [50, 100],
              defaultPageSize: 50,
              total: totalReports,
              pageSize: reportPerPage,
              onChange: (page, pageSize) => {
                setPageNumber(page);
                setReportPerPage(pageSize);
              }
            }}
            scroll={{ y: 600 }}
            onRow={(record) => {
              return {
                onClick: () => {
                  handleReportDetailModal(record.contentType, record.reportId);
                }
              };
            }}
          />
        </>
      )
    },
    {
      label: '댓글﹒답글',
      key: 'comment',
      children: (
        <>
          <Filter />
          <Table<ReportDataType>
            dataSource={reportList}
            columns={columns}
            rowKey={(record) => record.reportId}
            pagination={{
              pageSizeOptions: [50, 100],
              defaultPageSize: 50,
              total: totalReports,
              pageSize: reportPerPage,
              onChange: (page, pageSize) => {
                setPageNumber(page);
                setReportPerPage(pageSize);
              }
            }}
            scroll={{ y: 600 }}
            onRow={(record) => {
              return {
                onClick: () => {
                  handleReportDetailModal(record.contentType, record.reportId);
                }
              };
            }}
          />
        </>
      )
    }
  ];

  return (
    <DefaultLayout>
      <Layout>
        <PageHeader
          title="크리에이터 피드 관리"
          subTitle="크리에이터 피드의 신고된 글과 댓글을 관리합니다"
          style={{ margin: '16px 20px 0px 20px' }}
        />
        <Divider />
        <Content style={{ padding: '0px 24px', margin: '0px 20px' }}>
          <Tabs type="card" items={tabItems} onTabClick={handleChangeContentRequestType} />
        </Content>
      </Layout>

      {/* 신고 상세 모달 */}
      {reportDetail && (
        <ReportDetailModal
          reportDetail={reportDetail}
          isModalOpen={Boolean(selectedReportForModal)}
          handleModalClose={handleModalClose}
          handleReportProcess={handleReportProcess}
        />
      )}
    </DefaultLayout>
  );
}

export default CreatorFeedReportListPage;
