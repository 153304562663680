import { Input, Modal, ModalProps, Row } from 'antd';
import React from 'react';
import { pointApi } from '@src/apis/admin';

interface CancelCheckModalProps {
  modal: ModalProps;
  closeModal: () => unknown;
  pointEventId: string;
  refetchFn?: () => unknown;
  parentRefetchFn?: () => unknown;
}

export function CancelCheckModal({
  modal,
  closeModal,
  pointEventId,
  refetchFn,
  parentRefetchFn
}: CancelCheckModalProps) {
  const [input, setInput] = React.useState('');

  const handleCancelClick = React.useCallback(async () => {
    await pointApi.cancelPointEvent(pointEventId, {
      cancelReason: input
    });

    refetchFn?.();
    parentRefetchFn?.();
    closeModal();
  }, [pointEventId, input, refetchFn, parentRefetchFn, closeModal]);

  return (
    <Modal {...modal} onOk={handleCancelClick} okText="확인" cancelText="닫기">
      <Row>
        <div style={{ marginBottom: '8px' }}>취소 사유를 입력해주세요.</div>
        <Input onChange={(e) => setInput(e.target.value)} placeholder="취소 사유" />
      </Row>
    </Modal>
  );
}
